import Vue from 'vue';
import essentialsPlugin, { utils } from '@itccompliance/compliance-vue-essentials-plugin';
import store from '@/store';
import customFields from '@/components/form/fields';
import customValidators from '@/validators';

Vue.use(essentialsPlugin, {
  customFields,
  customValidators,
  promptDefaultOptions: {
    eventHandlers: [
      {
        event: 'show',
        handler: async (e, vm) => {
          await utils.async.waitRAF();
          const { content, dialog } = vm.$refs[vm.id].$refs;
          dialog.style.overflow = 'hidden';
          content.classList.add('transition-slide-in-top');
          await utils.async.waitRAF();
          content.classList.add('transition-slide-in');
        },
      },
    ],
  },
  store,
});
