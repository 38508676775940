import themeRepo from '@/api/repositories/theme';

export default {
  namespaced: true,
  actions: {
    async getDealerTheme(_, dealerId) {
      try {
        const { data } = await themeRepo.getDealerTheme(dealerId);
        return data;
      } catch { /** */ }
      return null;
    },
    reset() {},
  },
};
