const createErrorState = () => ({
  errorMessages: [],
});

export default {
  namespaced: true,
  state: createErrorState(),
  getters: {
    errorMessages: (state) => state.errorMessages,
  },
  mutations: {
    SAVE_ERROR_MESSAGE(state, error) {
      state.errorMessages.push(error);
    },
    RESET(state) {
      Object.assign(state, createErrorState());
    },
  },
  actions: {
    reset({ commit }) {
      commit('RESET');
    },
  },
};
