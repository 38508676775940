<template>
  <div>
    <b-row>
      <b-col>
        <p>{{ description }}</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-btn
          size="sm"
          @click="downloadFile()"
        >
          {{ label || Download }}
        </b-btn>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'DownloadFileField',
  props: {
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    path: {
      type: String,
      required: true,
    },
  },
  methods: {
    downloadFile() {
      window.open(`${process.env.VUE_APP_API_URL}${this.path}`, '_blank');
    },
  },
};
</script>
