import fandi from '@itccompliance/compliance-fandi-ui/src/store/config';
import agreements from './agreements';
import analytics from './analytics';
import auth from './auth';
import complaints from './complaints';
import deal from './deal';
import dealer from './dealer';
import documents from './documents';
import fandf from './fandf';
import legacyDocuments from './legacyDocuments';
import profile from './profile';
import recaptcha from './recaptcha';
import registration from './registration';
import reports from './reports';
import sidebar from './sidebar';
import staffList from './staffList';
import theme from './theme';
import titlebar from './titlebar';
import vehicle from './vehicle';
import whistleBlowing from './whistleBlowing';
import error from './error';
import dashboard from './dashboard';

export default {
  agreements,
  analytics,
  auth,
  complaints,
  deal,
  dealer,
  documents,
  fandf,
  fandi,
  'legacy-documents': legacyDocuments,
  profile,
  recaptcha,
  registration,
  reports,
  sidebar,
  'staff-list': staffList,
  theme,
  titlebar,
  vehicle,
  'whistle-blowing': whistleBlowing,
  error,
  dashboard,
};
