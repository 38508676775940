import dashboardRepo from '@/api/repositories/dashboard';

export default {
  namespaced: true,
  actions: {
    getMaintenanceMessages() {
      return dashboardRepo.getMaintenanceMessages();
    },
    getExpireCountdown() {
      return dashboardRepo.getExpireCountdown();
    },
    reset() { /*  */ },
  },
};
