import { utils } from '@itccompliance/compliance-vue-essentials-plugin';
import registrationRepo from '@/api/repositories/registration';
import LEVEL from '@/utils/enum/registration/level';

const customStepProperties = {
  user: {
    label: 'Account Creation',
    route: null,
  },
  firm: {
    visibleToLevels: [LEVEL.silver, LEVEL.gold],
  },
  finance: {
    visibleToLevels: [LEVEL.silver, LEVEL.gold],
  },
  'additional-details': {
    label: 'Additional Details',
  },
  confirmation: {
    label: 'Confirmation',
  },
  completed: {
    label: 'Complete',
  },
};
const steps = [
  'user',
  'firm',
  'finance',
  'additional-details',
  'confirmation',
  'completed',
].map((key) => ({
  key,
  label: `${utils.string.capitalise(key)} Details`,
  route: {
    name: 'Registration',
    params: {
      stepKey: key,
    },
  },
  complete: false,
  ...(customStepProperties[key] || {}),
}));
const createInitialState = () => ({
  currentStepKey: steps[0].key,
  level: LEVEL.gold,
  steps,
});

export default {
  namespaced: true,
  state: () => createInitialState(),
  getters: {
    ...utils.vuex.mapSimpleGetters([
      'currentStepKey',
      'level',
    ]),
    canComplete: (state, getters) => getters.steps
      .every((step) => step.complete || ['confirmation', 'completed'].includes(step.key)),
    currentStep: (state, getters) => getters.findStepByKey(getters.currentStepKey),
    currentStepIndex: (state, getters) => getters.steps.indexOf(getters.currentStep),
    findStepByKey: (state, getters) => (key) => getters.steps.find((step) => step.key === key),
    previousStep: (state, getters) => getters.steps[getters.currentStepIndex - 1],
    nextStep: (state, getters) => getters.steps[getters.currentStepIndex + 1],
    steps: (state, { level }) => state.steps
      .filter((step) => !step.visibleToLevels || step.visibleToLevels.includes(level)),
  },
  mutations: {
    ...utils.vuex.mapSimpleMutations([
      'currentStepKey',
      'level',
    ]),
    RESET(state) {
      Object.assign(state, createInitialState());
    },
  },
  actions: {
    setCurrentStepByKey({ commit, getters }, key) {
      const step = getters.findStepByKey(key);
      if (step) commit('SET_CURRENT_STEP_KEY', key);
    },
    setLevelByKey({ commit }, key) {
      commit('SET_LEVEL', LEVEL[key]);
    },
    async init({ commit, dispatch }) {
      const response = await registrationRepo.init();
      const { level, step } = response?.data?.data || {};
      if (level) dispatch('setLevelByKey', level);
      if (step) commit('SET_CURRENT_STEP_KEY', step);
      return response;
    },
    async getStep({ dispatch, getters }, stepKey) {
      try {
        const response = await registrationRepo.getStep(stepKey);
        for (let i = 0; getters.steps[i].key !== stepKey; i += 1) {
          const step = getters.steps[i];
          if (!step.complete) step.complete = true;
        }
        const level = response?.data?.data?.additional_data?.level;
        if (level) dispatch('setLevelByKey', level);
        return response;
      } finally { /*  */ }
    },
    async saveStep({ getters }, { stepKey, data, complete = true }) {
      const response = await registrationRepo.saveStep(stepKey, data);
      const step = getters.findStepByKey(stepKey);
      step.complete = complete;
      return response;
    },
    reset({ commit }) {
      commit('RESET');
    },
  },
};
