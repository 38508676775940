import Repository from '../Repository';

export class ReportsRepository extends Repository {
  getReport(name, params) {
    return this.client.get(`reports/${name}`, { params });
  }

  getComplianceReport(id) {
    return this.client.get(`reports/compliance/${id}`);
  }

  getSaleData(params) {
    return this.client.get('reports/sale-data', { params });
  }
}

export default new ReportsRepository();
