import checkboxGroupButtons from './checkbox-group-buttons.vue';
import date from './date.vue';
import file from './file.vue';
import getAddress from './get-address.vue';
import hidden from './hidden.vue';
import licenceLookup from './licence-lookup/licence-lookup.vue';
import radioButton from './radio-button.vue';
import radioSubtext from './radio-subtext.vue';
import rating from './rating.vue';
import selectFilterable from './select-filterable.vue';
import selectManufacturer from './select-manufacturer.vue';
import selectWithOther from './select-with-other.vue';
import typeahead from './typeahead.vue';
import year from './year.vue';
import details from './details.vue';
import downloadFile from './download-file.vue';

export default [
  checkboxGroupButtons,
  date,
  file,
  getAddress,
  hidden,
  licenceLookup,
  radioButton,
  radioSubtext,
  rating,
  selectFilterable,
  selectManufacturer,
  selectWithOther,
  typeahead,
  year,
  details,
  downloadFile,
];
