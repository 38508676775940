var render = function render(){var _vm=this,_c=_vm._self._c;return _c('TypeaheadField',_vm._g(_vm._b({},'TypeaheadField',{
    ..._vm.$attrs,
    ..._vm.$props,
    class: `${_vm.fieldClass}--select-manufacturer`,
    disabled: _vm.disabled || _vm.isLoadingManufacturers,
    options: _vm.options,
    placeholder: _vm.placeholder,
  },false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }