import Repository from '../Repository';

export class ProfileRepository extends Repository {
  getProfile() {
    return this.client.get('profile');
  }

  updateProfile(payload) {
    return this.client.put('profile', payload);
  }
}

export default new ProfileRepository();
