<template>
  <div
    :id="id"
    :class="`${fieldClass}--date`"
  >
    <b-input-group>
      <b-input-group-prepend>
        <b-button
          :id="datePickerButtonId"
          ref="datePickerButton"
          class="rounded-left"
          :disabled="disabled"
          :variant="stateVariant"
        >
          <BIconCalendar3 />
        </b-button>
        <Popover
          ref="popover"
          :target="datePickerButtonId"
        >
          <Datepicker
            v-bind="pickerProps"
            calendar-class="border-0"
            :disabled="disabled"
            inline
            :value="mutableValue"
            @input="onPick"
          />
        </Popover>
      </b-input-group-prepend>
      <b-form-input
        v-for="(field, index) of inputFields"
        :key="field.key"
        :ref="field.key"
        v-bind="field"
        class="flex-grow-0"
        :disabled="disabled"
        :state="stateComputed"
        :style="{
          'min-width': `${index === inputFields.length - 1 && typeof internalState === 'boolean'
            ? field.minWidth + 1
            : field.minWidth}rem`
        }"
        :value="mutableDate[field.key]"
        @input="onFieldInput(field, $event)"
      />
    </b-input-group>
    <b-form-invalid-feedback
      :state="stateComputed"
      v-text="invalidFeedback"
    />
  </div>
</template>

<script>
import { fieldMixin, utils } from '@itccompliance/compliance-vue-essentials-plugin';
import { BIconCalendar3 } from 'bootstrap-vue';
import formatDate from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import isValidDate from 'date-fns/isValid';
import Datepicker from 'vuejs-datepicker';
import Popover from '@/components/global/Popover.vue';

const computeDateLimit = (vm, limit) => {
  try {
    if (vm[limit]) return parseISO(vm[limit]);
    const relativeKey = `${limit}Relative`;
    if (vm[relativeKey]) return utils.date.getRelativeDate(vm[relativeKey]);
  } catch { /*  */ }
  return null;
};
const createInitialMutableDate = () => ({
  y: null,
  m: null,
  d: null,
});

export default {
  name: 'DateField',
  components: {
    BIconCalendar3,
    Datepicker,
    Popover,
  },
  mixins: [
    fieldMixin,
  ],
  props: {
    fieldOrder: {
      type: String,
      default: 'dmy',
    },
    format: {
      type: String,
      default: 'y-MM-dd',
    },
    max: {
      type: String,
      default: null,
    },
    maxRelative: {
      type: String,
      default: null,
    },
    min: {
      type: String,
      default: null,
    },
    minRelative: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    inputs: {
      y: {
        maxlength: 4,
        minWidth: 5,
        placeholder: 'YYYY',
      },
      m: {
        maxlength: 2,
        minWidth: 3.5,
        placeholder: 'MM',
      },
      d: {
        maxlength: 2,
        minWidth: 3.5,
        placeholder: 'DD',
      },
    },
    mutableDate: createInitialMutableDate(),
  }),
  computed: {
    datePickerButtonId() {
      return `${this.id}-button`;
    },
    inputFields() {
      const alignments = ['right', 'center', 'left'];
      return this.fieldOrder.split('').map((key, index) => ({
        ...this.inputs[key],
        class: `text-${alignments[index]}`,
        index,
        key,
      }));
    },
    isDateEntered() {
      return Object.values(this.mutableDate).every((v) => !!v);
    },
    isValidDate() {
      if (!this.isDateEntered) return null;
      try {
        const { y, m, d } = this.mutableDate;
        return isValidDate(parseISO([
          y.padStart(4, '0'),
          m.padStart(2, '0'),
          d.padStart(2, '0'),
        ].join('-')));
      } catch {
        return false;
      }
    },
    isWithinLimits() {
      if (!this.isValidDate) return null;
      const date = this.ymdToDate(this.mutableDate);
      const checkDateAgainstLimit = (limit, comparison) => !limit
        || comparison(date, limit)
        || isSameDay(date, limit);
      return checkDateAgainstLimit(this.minComputed, isAfter)
        && checkDateAgainstLimit(this.maxComputed, isBefore);
    },
    internalState() {
      return this.isValidDate && this.isWithinLimits;
    },
    invalidFeedback() {
      if (this.internalState === false) {
        if (this.isValidDate === false) return 'Must be a valid date';
        if (this.isWithinLimits === false) {
          const formatParts = {
            d: 'dd',
            m: 'MM',
            y: 'y',
          };
          const format = this.fieldOrder.split('').map((k) => formatParts[k]).join('/');
          const doFormatDate = (date) => formatDate(date, format);
          const maxFormatted = this.maxComputed ? doFormatDate(this.maxComputed) : null;
          const minFormatted = this.minComputed ? doFormatDate(this.minComputed) : null;
          if (maxFormatted && minFormatted) return `Must be between ${minFormatted} and ${maxFormatted} (inclusive)`;
          if (maxFormatted) return `Must be ${maxFormatted} or before`;
          if (minFormatted) return `Must be ${minFormatted} or after`;
        }
      }
      return null;
    },
    maxComputed() {
      return computeDateLimit(this, 'max');
    },
    minComputed() {
      return computeDateLimit(this, 'min');
    },
    startDate() {
      return (this.minComputed && isAfter(this.minComputed, new Date()))
        ? this.minComputed
        : this.maxComputed;
    },
    pickerProps() {
      return {
        disabledDates: {
          to: this.minComputed,
          from: this.maxComputed,
        },
        openDate: this.startDate,
        highlighted: {
          dates: [new Date()],
        },
      };
    },
    stateComputed() {
      if ([this.state, this.internalState].includes(false)) return false;
      return this.state;
    },
    stateVariant() {
      switch (this.stateComputed) {
        case true:
          return 'success';
        case false:
          return 'danger';
        default:
          return 'primary';
      }
    },
  },
  watch: {
    internalState: {
      handler(state) {
        if (state === false) this.mutableValue = null;
      },
    },
    mutableDate: {
      immediate: false,
      deep: true,
      handler(date) {
        if (this.internalState) {
          this.mutableValue = formatDate(this.ymdToDate(date), this.format);
        } else this.mutableValue = null;
      },
    },
  },
  methods: {
    parseValue(v) {
      if (!v) return null;
      try {
        const isDateStringOnly = (str) => /^(\d+-){2}\d+$/.test(str);
        const fullDate = isDateStringOnly(v) ? `${v}T00:00:00Z` : v;
        const date = parseISO(fullDate);
        this.mutableDate = this.dateToYmd(date);
        return formatDate(date, this.format);
      } catch (e) {
        return null;
      }
    },
    transformValue(v) {
      if (!v) this.mutableDate = createInitialMutableDate();
      return fieldMixin.methods.transformValue(v);
    },
    parseInitialValue(v) {
      return this.parseValue(v);
    },
    onFieldInput(field, value) {
      this.mutableDate[field.key] = value;
      if (field.index < this.inputFields.length - 1 && value.length === field.maxlength) {
        const nextField = this.inputFields[field.index + 1];
        this.$refs[nextField.key][0].$el.focus();
      }
    },
    onPick(date) {
      Object.assign(this.mutableDate, this.dateToYmd(date));
      this.$refs.popover.close();
      this.$refs.datePickerButton.blur();
    },
    dateToYmd(date) {
      const pad = (v) => v.toString().padStart(2, '0');
      return {
        y: date.getUTCFullYear().toString(),
        m: pad(date.getUTCMonth() + 1),
        d: pad(date.getUTCDate()),
      };
    },
    ymdToDate(date) {
      const { y, m, d } = date;
      return new Date(y, m - 1, d);
    },
  },
};
</script>
