<template>
  <vue-bootstrap-typeahead
    :id="id"
    :ref="id"
    :class="`${fieldClass}--typeahead`"
    :data="optionsComputed"
    :input-class="typeof state === 'boolean' ? `is-${state ? '' : 'in'}valid` : null"
    :min-matching-chars="minMatchingChars"
    :show-all-results="!mutableValue"
    show-on-focus
    :value="mutableValue"
    v-bind="{
      placeholder: 'Start typing...',
      ...$attrs
    }"
    @hit="setMutableValue"
    @input="setMutableValue"
  />
</template>

<script>
import { fieldMixin, utils } from '@itccompliance/compliance-vue-essentials-plugin';
import VueBootstrapTypeahead from '@vue-bootstrap-components/vue-bootstrap-autocomplete';

export default {
  name: 'TypeaheadField',
  components: { VueBootstrapTypeahead },
  mixins: [fieldMixin],
  props: {
    autoMatch: {
      type: Boolean,
      default: true,
    },
    autoMatchCaseSensitive: {
      type: Boolean,
      default: false,
    },
    minMatchingChars: {
      type: Number,
      default: 1,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data: (vm) => ({
    optionsComputed: vm.getOptions(),
  }),
  watch: {
    disabled(disabled) {
      const input = this.$refs[this.id].$el.querySelector('input');
      if (input) input.disabled = disabled;
    },
    display_attributes: {
      deep: true,
      handler() {
        this.updateOptionsComputed();
      },
    },
    field: {
      deep: true,
      handler() {
        this.updateOptionsComputed();
      },
    },
    options() {
      this.updateOptionsComputed();
    },
  },
  methods: {
    async autoSelectValue(value, options) {
      const getOptionValue = (option) => option?.value ?? option;
      const match = this.optionsComputed.find((option) => {
        try {
          const optionValue = getOptionValue(option);
          return this.autoMatchCaseSensitive
            ? optionValue === value
            : optionValue.toLowerCase() === value.toLowerCase();
        } catch {
          return false;
        }
      });
      if (options?.waitTick) await this.$nextTick();
      if (match) this.mutableValue = getOptionValue(match);
    },
    setMutableValue(value) {
      this.mutableValue = value;
      if (this.autoMatch) this.autoSelectValue(value);
    },
    transformMutableValue(mutableValue) {
      this.updateOptionsComputed();
      return mutableValue === ''
        ? null
        : mutableValue;
    },
    transformValue(value) {
      const mutableValue = value === null
        ? ''
        : value;
      if (this.autoMatch) this.autoSelectValue(mutableValue, { waitTick: true });
      return mutableValue;
    },
    parseInitialValue(value) {
      return this.transformValue(value);
    },
    getOptions() {
      const sources = [
        this.options,
        utils.object.deepGet(this, 'field.options'),
        utils.object.deepGet(this, 'display_attributes.data'),
        utils.object.deepGet(this, 'display_attributes.options'),
      ];
      for (let i = 0; i < sources.length; i += 1) {
        const options = sources[i];
        if (Array.isArray(options) && options.length) return options;
      }
      return [];
    },
    updateOptionsComputed() {
      const options = this.getOptions();
      if (options !== this.optionsComputed) this.optionsComputed = options;
    },
  },
};
</script>
