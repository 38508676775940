import { utils } from '@itccompliance/compliance-vue-essentials-plugin';
import SecureLS from 'secure-ls';
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import metaRepo from '@/api/repositories/meta';
import modules from './modules';

Vue.use(Vuex);
const ls = new SecureLS({ encodingType: 'aes', isCompression: false });

const createInitialState = () => ({
  meta: {},
});

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: 'apex-state',
      paths: ['analytics.consent', 'auth', 'dealer', 'profile'],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  state: createInitialState(),
  getters: {
    ...utils.vuex.mapSimpleGetters(['meta']),
  },
  mutations: {
    ...utils.vuex.mapSimpleMutations(['meta']),
    RESET(state) {
      Object.assign(state, createInitialState());
    },
  },
  actions: {
    reset({ commit, dispatch }) {
      Object.keys(modules).forEach((moduleKey) => {
        dispatch(`${moduleKey}/reset`);
      });
      commit('RESET');
    },
    async getMeta({ commit, dispatch }) {
      let meta = {};
      try {
        const { data } = await metaRepo.getMeta();
        meta = data.data;
        commit('dealer/SET_CURRENT_DEALER_ID', meta.current_dealer_id);
        dispatch('profile/getProfile');
        if (meta.permissions) commit('auth/SET_PERMISSIONS', meta.permissions);
      } finally {
        commit('SET_META', meta);
        await dispatch('dealer/verifyCurrentDealer');
      }
    },
  },
  modules,
});
