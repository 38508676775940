<template>
  <b-popover
    ref="popover"
    boundary="viewport"
    boundary-padding="16"
    custom-class="shadow"
    delay="0"
    no-fade
    triggers="focus"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
    <template
      v-if="!$attrs.title"
      #title
    >
      <slot name="title" />
    </template>
  </b-popover>
</template>
<script>
export default {
  methods: {
    close() {
      this.$refs.popover.$emit('close');
    },
  },
};
</script>
