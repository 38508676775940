<template>
  <div class="compliance-input--rating">
    <div class="d-flex justify-content-center">
      <a
        v-for="(option, ix) in options"
        :key="`option-${ix}`"
        :disabled="disabled"
        href="#"
        class="compliance-input--rating__button h1 px-4"
        @mouseenter="showValue(option.value)"
        @focus="showValue(option.value)"
        @mouseleave="showValue(mutableValue || null)"
        @blur="showValue(mutableValue || null)"
        @click.prevent="setValue(option.value)"
      >
        <b-icon :icon="currentIcons[ix]" />
      </a>
    </div>
    <hr
      class="mt-0"
      :class="{
        'border-danger': state === false,
        'border-success': state === true,
      }"
      width="80%"
    >
    <div
      class="compliance-input--rating__display-text text-center"
      v-text="ratingText"
    />
  </div>
</template>
<script>
import { fieldMixin } from '@itccompliance/compliance-vue-essentials-plugin';

export default {
  name: 'RatingField',
  mixins: [fieldMixin],
  props: {
    icon: {
      type: Object,
      default: () => ({
        selected: 'star-fill',
        unselected: 'star',
      }),
    },
    options: {
      type: Array,
      default() {
        return this.field.options || [
          { value: 1, text: 'One' },
          { value: 2, text: 'Two' },
          { value: 3, text: 'Three' },
          { value: 4, text: 'Four' },
          { value: 5, text: 'Five' },
        ];
      },
    },
  },
  data: (vm) => ({
    displayValue: vm.value || null,
  }),
  computed: {
    currentIcons() {
      const { options } = this;
      return options.map((o, ix) => (
        options.findIndex((option) => option.value === this.displayValue) >= ix
          ? this.icon.selected
          : this.icon.unselected));
    },
    ratingText() {
      if (!this.displayValue && this.field.disabled) return '';
      if (this.displayValue) {
        const option = this.options.find((o) => o.value === this.displayValue);
        return option ? option.text : '';
      }
      return 'Please select a rating';
    },
  },
  watch: {
    mutableValue(value) {
      this.displayValue = value || null;
    },
  },
  methods: {
    showValue(value) {
      if (!this.disabled) {
        this.displayValue = value;
      }
    },
    setValue(value) {
      if (!this.disabled) {
        this.mutableValue = value;
      }
    },
  },
};
</script>
