<template>
  <div
    :id="id"
    :class="`${fieldClass}--licence-lookup`"
  >
    <LicenceImage
      v-if="mutableValue && licenceData.image"
      class="mb-4"
      :src="getImageBase64String(licenceData.image)"
      start-hidden
    />
    <b-row>
      <b-col>
        <b-form-input
          v-model="licenceData.licence_number"
          :disabled="disabled"
          :state="state"
          trim
          @input="mutableValue = null"
        />
      </b-col>
      <b-col
        class="pl-0"
        cols="auto"
      >
        <b-btn
          :disabled="disabled || anyLoading"
          @click="lookupLicence"
        >
          Search
        </b-btn>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { fieldMixin, loadingMixin } from '@itccompliance/compliance-vue-essentials-plugin';
import { mapGetters } from 'vuex';
import lookupRepo from '@/api/repositories/lookup';
import LicenceImage from './LicenceImage.vue';
import LicenceNotFound from './LicenceNotFound.vue';

export default {
  name: 'LicenceLookupField',
  components: { LicenceImage },
  mixins: [fieldMixin, loadingMixin],
  props: {
    licence_number: { // eslint-disable-line vue/prop-name-casing
      type: String,
      default: null,
    },
    image: {
      type: String,
      default: null,
    },
  },
  data: (vm) => ({
    licenceData: {
      image: vm.image,
      licence_number: vm.licence_number,
    },
  }),
  computed: {
    ...mapGetters('deal', ['deal']),
  },
  methods: {
    async lookupLicence() {
      this.setLoading('licence-lookup');
      try {
        const response = await lookupRepo
          .lookupLicence(this.deal.id, this.licenceData.licence_number);
        const { data } = response.data;
        if (data) {
          const res = await this.$compliancePrompt.prompt({
            title: 'Is this licence correct?',
            canCancel: true,
            cancelText: 'No',
            okText: 'Yes',
            component: {
              render: (h) => h(LicenceImage, {
                props: {
                  src: this.getImageBase64String(data.image),
                },
              }),
            },
          });
          if (res) {
            this.mutableValue = data.id;
            Object.assign(this.licenceData, data);
          } else this.mutableValue = null;
        } else {
          this.mutableValue = null;
          throw new Error('No licence found');
        }
      } catch {
        this.$compliancePrompt.prompt({
          title: 'Licence not found',
          component: LicenceNotFound,
          dismissable: true,
          okText: 'Close',
        });
      }
      this.unsetLoading('licence-lookup');
    },
    getImageBase64String(base64) {
      return `data:image/jpeg;base64,${base64}`;
    },
  },
};
</script>
