import { utils } from '@itccompliance/compliance-vue-essentials-plugin';
import vehicleRepo from '@/api/repositories/vehicle';

const createInitialState = () => ({
  loadingManufacturersPromise: null,
  manufacturers: [],
});

export default {
  namespaced: true,
  state: createInitialState(),
  getters: {
    ...utils.vuex.mapSimpleGetters(['manufacturers']),
    isLoadingManufacturers: ({ loadingManufacturersPromise }) => !!loadingManufacturersPromise,
  },
  mutations: {
    ...utils.vuex.mapSimpleMutations([
      'loadingManufacturersPromise',
      'manufacturers',
    ]),
    RESET(state) {
      Object.assign(state, createInitialState());
    },
  },
  actions: {
    reset({ commit }) {
      commit('RESET');
    },
    async getManufacturers({ commit, getters }, source) {
      if (!getters.isLoadingManufacturers) {
        const getManufacturersPromise = new Promise((resolve) => {
          vehicleRepo.getManufacturers(source)
            .then((response) => {
              const { data } = response.data;
              const manufacturers = data && Array.isArray(data) ? data : [];
              commit('SET_MANUFACTURERS', manufacturers);
            })
            .finally(() => {
              commit('SET_LOADING_MANUFACTURERS_PROMISE', null);
              resolve();
            });
        });
        commit('SET_LOADING_MANUFACTURERS_PROMISE', getManufacturersPromise);
      }
      return getters.loadingManufacturersPromise;
    },
    async getManufacturerModels(context, { manufacturer, source }) {
      const { data } = await vehicleRepo.getManufacturerModels(manufacturer.id, source);
      return data.data;
    },
  },
};
