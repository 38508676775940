import Validations from '@itccompliance/compliance-vue-essentials-plugin/src/classes/Validations';
import customValidators from './validatorsExceptRepeatableAllValid';

const validations = new Validations(customValidators);

/*
  Because of how the Validations.getValidation method works, the ruleString needs to have params in
  order to use the "function that returns a function" validation style that allows the params and
  other arguments to be used.
  This validator doesn't actually need any params, but still needs access to those extra args.
  For that reason, you need to specify arbitrary params in the ruleString when using this validator
  for it to work.
  e.g.
    rules: ['repeatable-all-valid:_'] // note the trailing `:_`
*/
export default {
  validation: (params, field) => (repeatableValues) => {
    if (!repeatableValues) return true;
    const { fields } = field.form_model;
    const validatedFields = fields.filter((f) => f.rules);
    return repeatableValues.every((repeatableValue) => validatedFields.every((f) => {
      if (f.id in repeatableValue) {
        return f.rules
          .every((ruleString) => {
            const validator = validations.getValidation(ruleString, f, repeatableValue, fields);
            const repeatableFieldValue = repeatableValue[f.id];
            return validator(repeatableFieldValue);
          });
      }
      return false;
    }));
  },
  message: () => 'All items must be valid',
};
