import Repository from '../Repository';

export class DealerRepository extends Repository {
  setCurrentDealer(dealerId) {
    return this.client.post('auth/set-dealer', { dealer_id: dealerId });
  }

  getWelcomePackConfirmationText() {
    return this.client.get('dealer/welcome-pack-confirmation-text');
  }
}

export default new DealerRepository();
