import Repository from '../Repository';

export class DashboardRepository extends Repository {
  getMaintenanceMessages() {
    return this.client.get('/maintenance-messages');
  }

  getExpireCountdown() {
    return this.client.get('/expiry-countdown');
  }
}

export default new DashboardRepository();
