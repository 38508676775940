export default {
  namespaced: true,
  state: {
    consentedUsers: [],
    declinedUsers: [],
  },
  getters: {
    consentProvidedForUser: ({ consentedUsers }) => (user) => consentedUsers.includes(user),
    consentDeclinedForUser: ({ declinedUsers }) => (user) => declinedUsers.includes(user),
    consentSpecifiedForUser: (state, {
      consentProvidedForUser,
      consentDeclinedForUser,
    }) => (user) => consentProvidedForUser(user) || consentDeclinedForUser(user),
  },
  mutations: {
    SET_USER_CONSENTED(state, user) {
      const { consentedUsers, declinedUsers } = state;
      if (declinedUsers.includes(user)) declinedUsers.splice(declinedUsers.indexOf(user), 1);
      if (!consentedUsers.includes(user)) consentedUsers.push(user);
    },
    SET_USER_DECLINED(state, user) {
      const { consentedUsers, declinedUsers } = state;
      if (consentedUsers.includes(user)) consentedUsers.splice(consentedUsers.indexOf(user), 1);
      if (!declinedUsers.includes(user)) declinedUsers.push(user);
    },
    UNSET_USER_CONSENT(state, user) {
      const { consentedUsers, declinedUsers } = state;
      if (declinedUsers.includes(user)) declinedUsers.splice(declinedUsers.indexOf(user), 1);
      if (consentedUsers.includes(user)) consentedUsers.splice(consentedUsers.indexOf(user), 1);
    },
  },
  actions: {
    setUserConsent({ commit }, { user, consent }) {
      commit(`SET_USER_${consent ? 'CONSENTED' : 'DECLINED'}`, user);
    },
    unsetUserConsent({ commit }, user) {
      commit('UNSET_USER_CONSENT', user);
    },
  },
};
