import reportsRepo from '@/api/repositories/reports';

export default {
  namespaced: true,
  actions: {
    getReport(context, { name, params }) {
      return reportsRepo.getReport(name, params);
    },

    getComplianceReport(context, id) {
      return reportsRepo.getComplianceReport(id);
    },

    getSaleData(context, { params }) {
      return reportsRepo.getSaleData(params);
    },
    reset() {},
  },
};
