import { utils } from '@itccompliance/compliance-vue-essentials-plugin';

const nonPostcodeAddressFieldKeys = [
  'property_name_number',
  'street',
  'town',
  'county',
];
const addressFieldLabels = {
  property_name_number: 'Property name/number',
};
const formatLabelFromKey = (key) => utils.string.capitalise(key.replace(/_/g, ' '));

export {
  formatLabelFromKey,
  nonPostcodeAddressFieldKeys,
};
export default (address = {}) => ({
  fields: [
    ...nonPostcodeAddressFieldKeys.map((key) => {
      const value = address[key] || null;
      return {
        id: key,
        display_type: 'text',
        label: addressFieldLabels[key] || formatLabelFromKey(key),
        start_hidden: !value,
        value,
      };
    }),
    {
      id: 'postcode',
      display_type: 'get-address',
      label: 'Postcode',
      rules: ['required', 'postcode'],
      value: address.postcode || null,
    },
  ],
});
