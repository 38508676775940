export default () => ({
  fields: [
    {
      id: 'alert',
      label: '<div class="alert alert-danger m-0">Our systems show that the documentation email that was sent to this customer was not successfully delivered. This may be due to a technical error or because the email address was entered incorrectly. You can not continue with this sale until the customer has received their documentation. Please re-enter their email address and resend the email.</div>',
    },
    {
      id: 'email',
      key: 'email',
      label: 'Email',
      display_type: 'email',
      rules: [
        'email',
        'required',
      ],
    },
    {
      id: 'email_confirm',
      label: 'Confirm email',
      display_type: 'email',
      rules: [
        'email',
        'required',
        'eq_f:email,false',
      ],
    },
  ],
});
