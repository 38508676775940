import accepted from './accepted';
import beforeF from './beforeF';
import integerCurrency from './integerCurrency';
import lenF from './lenF';
import neq from './neq';
import withinDurationF from './withinDurationF';
import tel from './tel';
import telLandline from './telLandline';
import telMobile from './telMobile';
import productsDiscussed from './productsDiscussed';
import vin from './vin';

export default {
  accepted,
  before_f: beforeF,
  integer_currency: integerCurrency,
  len_f: lenF,
  neq,
  within_duration_f: withinDurationF,
  tel,
  tel_landline: telLandline,
  tel_mobile: telMobile,
  products_discussed: productsDiscussed,
  vin,
};
