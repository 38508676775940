<template>
  <div
    class="pl-5"
    v-html="detailsText"
  />
</template>

<script>
export default {
  name: 'DetailsField',
  props: {
    detailsText: {
      type: String,
      default: '',
    },
  },
};
</script>
