<template>
  <b-row class="licence-image">
    <b-col>
      <img
        alt="Licence"
        class="d-block mx-auto w-100"
        :class="`licence-image__image${hidden ? '--hidden' : ''}`"
        :src="src"
      >
      <div
        :class="[
          'licence-image__visibility-toggle',
          'position-absolute',
          'cursor-pointer',
          'd-flex',
          'align-items-center',
          'mr-3',
          'bg-white',
          'px-2',
          'py-1',
          'rounded',
        ]"
        @click="hidden = !hidden"
        @keypress.enter="hidden = !hidden"
      >
        <small>{{ hidden ? 'Show' : 'Hide' }} licence</small>
        <b-icon
          class="h5 my-0 ml-1"
          :icon="`eye${hidden ? '' : '-slash'}`"
        />
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    startHidden: {
      type: Boolean,
      default: false,
    },
  },
  data: (vm) => ({
    hidden: vm.startHidden,
  }),
};
</script>
