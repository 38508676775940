/* istanbul ignore next */const Reports = () => import(/* webpackChunkName: "reports" */ '@/views/Reports.vue');
/* istanbul ignore next */const ReportLinks = () => import(/* webpackChunkName: "report-links" */ '@/views/reports/Links.vue');
/* istanbul ignore next */const SalesAuditReport = () => import(/* webpackChunkName: "report-sales-audit" */ '@/views/reports/SalesAudit.vue');
/* istanbul ignore next */const TeamProfileReport = () => import(/* webpackChunkName: "report-team-profile" */ '@/views/reports/TeamProfile.vue');
/* istanbul ignore next */const MyProfileReport = () => import(/* webpackChunkName: "report-my-profile" */ '@/views/reports/MyProfile.vue');
/* istanbul ignore next */const MIReport = () => import(/* webpackChunkName: "report-apex-mi" */ '@/views/reports/MI.vue');
/* istanbul ignore next */const GroupDeals = () => import(/* webpackChunkName: "report-group-deals" */ '@/views/reports/GroupDeals.vue');
/* istanbul ignore next */const ComplianceReports = () => import(/* webpackChunkName: "report-compliance-reports" */ '@/views/reports/ComplianceReports.vue');
/* istanbul ignore next */const SalesDataReport = () => import(/* webpackChunkName: "report-sales-data" */ '@/views/reports/SalesData.vue');
/* istanbul ignore next */const LostSale = () => import(/* webpackChunkName: "report-lost-sale" */ '@/views/reports/LostSale.vue');

export default [
  {
    path: '/reports',
    component: Reports,
    children: [
      {
        name: 'Reports',
        path: '',
        component: ReportLinks,
        meta: {
          title: 'Reports',
          permissionRequirements: {
            hasPermission: 'reports',
          },
        },
      },
      {
        name: 'Sales Audit Report',
        path: 'sales-audit',
        component: SalesAuditReport,
        meta: {
          title: ['Sales Audit Report', 'Reports'],
          permissionRequirements: {
            hasPermission: 'reports.sales_audit',
          },
        },
      },
      {
        name: 'Team Profile Report',
        path: 'team-profile',
        component: TeamProfileReport,
        meta: {
          title: ['Team Profile Report', 'Reports'],
          permissionRequirements: {
            hasPermission: 'reports.team_profile',
          },
        },
      },
      {
        name: 'My Profile Report',
        path: 'my-profile',
        component: MyProfileReport,
        meta: {
          title: ['My Profile Report', 'Reports'],
          permissionRequirements: {
            hasPermission: 'reports.my_profile',
          },
        },
      },
      {
        name: 'APEX MI Report',
        path: 'apex-mi',
        component: MIReport,
        meta: {
          title: ['APEX MI Report', 'Reports'],
          permissionRequirements: {
            hasPermission: 'reports.apex_mi',
          },
        },
      },
      {
        name: 'Lost Sale Report',
        path: 'lost-sale',
        component: LostSale,
        meta: {
          title: ['Lost Sale Report', 'Reports'],
          permissionRequirements: {
            hasPermission: 'reports.lost_sale',
          },
        },
      },
      {
        name: 'Group Deals',
        path: 'group-deals',
        component: GroupDeals,
        meta: {
          title: 'Group Deals',
          preferenceRequirements: {
            currentDealerHasPreference: 'access_across_group',
          },
        },
      },
      {
        name: 'Compliance Reports',
        path: 'compliance-reports',
        component: ComplianceReports,
        meta: {
          title: 'Compliance Reports',
          permissionRequirements: {
            hasPermission: 'reports.compliance',
          },
        },
      },
      {
        name: 'Sales Data',
        path: 'sales-data',
        component: SalesDataReport,
        meta: {
          title: 'Sales Data',
          permissionRequirements: {
            hasPermission: 'reports.sale_data',
          },
        },
      },
    ],
  },
];
