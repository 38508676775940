import { utils } from '@itccompliance/compliance-vue-essentials-plugin';
import isAfter from 'date-fns/isAfter';
import isWithinInterval from 'date-fns/isWithinInterval';
import parseISO from 'date-fns/parseISO';
import dateFilter from '@/filters/date';

export default {
  validation: (params, field, formData, fields) => (val) => {
    if (!val) return true;
    const [targetFieldKey, duration] = params;
    const { id: targetFieldId } = fields.find((f) => f.key === targetFieldKey);
    const targetFieldValue = formData[targetFieldId];
    if (!targetFieldValue) return true;
    const currentFieldDate = parseISO(val);
    const baseDate = parseISO(targetFieldValue);
    const endDate = utils.date.getRelativeDate(duration, baseDate);
    const interval = isAfter(endDate, baseDate)
      ? { start: baseDate, end: endDate }
      : { start: endDate, end: baseDate };
    return isWithinInterval(currentFieldDate, interval);
  },
  message: (params, field, formData, fields) => {
    const [targetFieldKey, duration, formatString] = params;
    const { id: targetFieldId } = fields.find((f) => f.key === targetFieldKey);
    const targetFieldValue = formData[targetFieldId];
    const baseDate = parseISO(targetFieldValue);
    const endDate = utils.date.getRelativeDate(duration, baseDate);
    const interval = isAfter(endDate, baseDate)
      ? { start: baseDate, end: endDate }
      : { start: endDate, end: baseDate };
    return `Must be between ${dateFilter(interval.start, formatString)} and ${dateFilter(interval.end, formatString)}`;
  },
};
