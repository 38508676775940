<template>
  <div class="text-center">
    <b-row>
      <b-col class="display-1 mb-3">
        <b-icon icon="emoji-frown" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <p class="mb-0">
          Sorry, we have no matching records for that licence number.
        </p>
        <p>Please use the Licence Scanner app to scan the licence.</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <AppStoreBadge
          app-id="id1360251034"
          platform="apple"
        />
        <AppStoreBadge
          app-id="com.itccompliance.dls"
          platform="google"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AppStoreBadge from '@/components/global/AppStoreBadge.vue';

export default {
  components: { AppStoreBadge },
};
</script>
