import FAndIRoutes from '@itccompliance/compliance-fandi-ui/src/router/routes';

/* istanbul ignore next */const FAndI = () => import(/* webpackChunkName: "fandi" */ '@/views/fandi/FAndI.vue');
/* istanbul ignore next */const FAndIMenu = () => import(/* webpackChunkName: "fandi" */ '@/views/fandi/FAndIMenu.vue');

export default [
  {
    path: '/f-and-i',
    name: 'fandi',
    component: FAndI,
    meta: {
      title: 'F & I',
    },
    children: [
      {
        path: '',
        name: 'fandi-menu',
        component: FAndIMenu,
      },
      ...FAndIRoutes,
    ],
  },
];
