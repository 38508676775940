import { utils } from '@itccompliance/compliance-vue-essentials-plugin';
import profileRepo from '@/api/repositories/profile';

const createInitialState = () => ({
  profile: null,
  userTraining: {},
});

export default {
  namespaced: true,
  state: createInitialState(),
  getters: {
    ...utils.vuex.mapSimpleGetters(['profile', 'userTraining']),
    userHasTraining(state, getters) {
      return getters.userTraining.courses_completed
        || !getters.userTraining.training_check_on;
    },
  },
  mutations: {
    ...utils.vuex.mapSimpleMutations(['profile', 'userTraining']),
    RESET(state) {
      Object.assign(state, createInitialState());
    },
  },
  actions: {
    async getProfile({ commit }) {
      const response = await profileRepo.getProfile();
      const profile = response.data.data;
      commit('SET_PROFILE', profile);
      return profile;
    },
    async updateProfile({ commit }, data) {
      const response = await profileRepo.updateProfile(data);
      const profile = response.data.data;
      commit('SET_PROFILE', profile);
      return profile;
    },
    setUserTraining({ commit }, data) {
      commit('SET_USER_TRAINING', data);
    },
    reset({ commit }) {
      commit('RESET');
    },
  },
};
