<template>
  <div class="user-menu">
    <b-avatar
      id="user-avatar"
      href="#"
      tabindex="0"
      class="ml-3 mb-3"
      size="3rem"
      variant="primary"
    />
    <b-popover
      boundary="viewport"
      boundary-padding="16"
      custom-class="user-menu__popover shadow"
      delay="0"
      no-fade
      placement="top"
      target="user-avatar"
      title="My Account"
      triggers="focus"
    >
      <b-list-group flush>
        <template
          v-if="currentDealer && !isBaseDashboard"
        >
          <b-list-group-item disabled>
            Current dealer: {{ currentDealer.name }}
          </b-list-group-item>
          <b-list-group-item
            href="/app/dealer/documents/welcome-pack"
            target="_blank"
          >
            <BIconBagCheckFill class="mr-1" /> Welcome Pack
          </b-list-group-item>
        </template>
        <b-list-group-item
          :disabled="isLoading(profileLoadingKey)"
          href="#"
          @click="doUpdateProfile"
        >
          <BIconPersonCircle class="mr-1" /> Update Profile <b-spinner
            v-if="isLoading(profileLoadingKey)"
            small
          />
        </b-list-group-item>
        <b-list-group-item
          v-if="profile && consentSpecifiedForUser(profile.id)"
          href="#"
          @click="resetAnayltics"
        >
          <fa-icon
            class="mr-1"
            icon="chart-bar"
          /> Reset Analytics Consent
        </b-list-group-item>
        <b-list-group-item
          href="#"
          @click="showHelpPrompt"
        >
          <BIconInfoCircle class="mr-1" /> I Need Help
        </b-list-group-item>
        <b-list-group-item
          href="#"
          @click="showErrorPrompt"
        >
          <BIconExclamationTriangleFill class="mr-1" /> Error Messages
        </b-list-group-item>
        <b-list-group-item
          href="#"
          @click="doLogout"
        >
          <fa-icon
            class="mr-1"
            icon="sign-out-alt"
          /> Log out
        </b-list-group-item>
      </b-list-group>
    </b-popover>
  </div>
</template>

<script>
import { loadingMixin, utils } from '@itccompliance/compliance-vue-essentials-plugin';
import {
  BIconBagCheckFill, BIconInfoCircle, BIconPersonCircle, BIconExclamationTriangleFill,
} from 'bootstrap-vue';
import { mapActions, mapGetters } from 'vuex';
import EventBus from '@/services/EventBus';
import updateProfileFormModel from '@/assets/data/forms/models/profile';
import NeedHelp from '@/components/global/NeedHelp.vue';
import ErrorMessages from '@/components/global/ErrorMessages.vue';

export default {
  components: {
    BIconBagCheckFill,
    BIconInfoCircle,
    BIconPersonCircle,
    BIconExclamationTriangleFill,
  },
  mixins: [
    loadingMixin,
  ],
  data: () => ({
    profileLoadingKey: 'profile',
  }),
  computed: {
    ...mapGetters('analytics/consent', ['consentSpecifiedForUser']),
    ...mapGetters('dealer', ['currentDealer']),
    ...mapGetters('profile', ['profile']),
    isBaseDashboard() {
      return utils.object.deepGet(this, 'currentDealer.preferences.dashboard_type') === 'basic';
    },
  },
  methods: {
    ...mapActions('profile', [
      'getProfile',
      'updateProfile',
    ]),
    ...mapActions('analytics/consent', ['unsetUserConsent']),
    doLogout() {
      EventBus.$emit('logout');
    },
    async doUpdateProfile() {
      this.setLoading(this.profileLoadingKey);
      try {
        await this.getProfile();
      } finally {
        this.unsetLoading(this.profileLoadingKey);
      }
      this.$compliancePrompt.prompt({
        title: 'Update Profile',
        formModel: updateProfileFormModel(this.profile),
        beforeResolve: async (result) => {
          if (result) await this.updateProfile(result);
        },
      });
    },
    showHelpPrompt() {
      this.$compliancePrompt.prompt({
        title: 'Need Help?',
        component: NeedHelp,
        dismissable: true,
        modalProps: {
          hideFooter: true,
        },
      });
    },
    showErrorPrompt() {
      this.$compliancePrompt.prompt({
        title: 'Error Messages',
        component: ErrorMessages,
        dismissable: true,
        modalProps: {
          hideFooter: true,
        },
      });
    },
    async resetAnayltics() {
      const res = await this.$compliancePrompt.confirm(
        'Are you sure you would like to reset your anayltics consent? This will reload the page.',
        'Reset Analytics Consent',
      );
      if (res) {
        this.unsetUserConsent(this.profile.id);
        this.$router.go(); // Refresh the page
      }
    },
  },
};
</script>
