import createFormData from '@/utils/api/createFormData';
import Repository from '../Repository';

export class WhistleBlowingRepository extends Repository {
  sendReport(data) {
    return this.client.post('whistleblowing', createFormData(data), {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

export default new WhistleBlowingRepository();
