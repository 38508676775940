import documentsRepo from '@/api/repositories/documents';

export default {
  namespaced: true,
  actions: {
    reset() {},
    searchDocuments(context, params) {
      return documentsRepo.searchDocuments(params);
    },
    getPublicDocuments() {
      return documentsRepo.getPublicDocuments();
    },
    downloadDocument(context, documentId) {
      return documentsRepo.downloadDocument(documentId);
    },
  },
};
