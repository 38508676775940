import Repository from '../Repository';

export class DealRepository extends Repository {
  createDeal(payload) {
    return this.client.post('deal', payload);
  }

  getDeal(dealId) {
    return this.client.get(`deal/${dealId}`);
  }

  getActivity(dealId) {
    return this.client.get(`deal/${dealId}/activity`);
  }

  getDocuments(dealId) {
    return this.client.get(`deal/${dealId}/documents`);
  }

  getStep(dealId, step) {
    return this.client.get(`deal/${dealId}/step/${step}`);
  }

  saveStep(dealId, stepName, data) {
    return this.client.post(`deal/${dealId}/step/${stepName}`, data);
  }

  enableStep(dealId, stepName) {
    return this.client.post(`deal/${dealId}/step/${stepName}/enable`);
  }

  disableStep(dealId, stepName, reason) {
    return this.client.post(`deal/${dealId}/step/${stepName}/disable`, { reason });
  }

  verifyStep(dealId, stepName) {
    return this.client.post(`deal/${dealId}/step/${stepName}/verify`);
  }

  refuteStep(dealId, stepName) {
    return this.client.post(`deal/${dealId}/step/${stepName}/refute`);
  }

  completeCurrentPhase(dealId) {
    return this.client.post(`deal/${dealId}/phase/complete`);
  }

  requestEmailVerification(dealId, emailAddress) {
    return this.client.post(`deal/${dealId}/verify-email`, { email_address: emailAddress });
  }

  verifyEmail(dealId, token) {
    return this.client.get(
      `/deal/${dealId}/verify-email/${token}`,
      { preventEventBusEvents: true },
    );
  }

  changeApproach(dealId, approach) {
    return this.client.put(`deal/${dealId}/approaches`, { approach });
  }

  getDemandsAndNeeds(dealId) {
    return this.client.get(`deal/${dealId}/documents/demands-and-needs`);
  }

  completeDemandsAndNeeds(dealId, signatures) {
    return this.client.post(`deal/${dealId}/documents/demands-and-needs`, { signatures });
  }

  searchDeals(params) {
    return this.client.get('deal', {
      params: {
        ...params,
        orders: [['id', 'desc']],
      },
    });
  }

  searchGroupDeals(params) {
    return this.client.get('group-deals', {
      params: {
        ...params,
        orders: [['id', 'desc']],
      },
    });
  }

  registerPolicies(dealId, data) {
    return this.client.post(`/deal/${dealId}/insurance/policies/register`, data);
  }

  getPolicyRegistrationStatus(dealId, registrationReference) {
    return this.client.get(`/deal/${dealId}/insurance/policies/register/${registrationReference}`);
  }

  concludeMoratoriumEarly(dealId, productId) {
    return this.client.post(`deal/${dealId}/insurance/products/${productId}/moratorium/conclude`);
  }

  createCustomerSupportForm(data) {
    return this.client.post('customer-support', data);
  }

  confirmGIProduct(dealId, productId, confirmed = true) {
    return this.client.put(`deal/${dealId}/insurance/products/${productId}/confirm`, { confirmed });
  }

  unconfirmGIProduct(dealId, productId) {
    return this.confirmGIProduct(dealId, productId, false);
  }

  decidedGIProduct(dealId, productId, decided = true) {
    return this.client.put(`deal/${dealId}/insurance/products/${productId}/decide`, { decided });
  }

  undecidedGIProduct(dealId, productId) {
    return this.decidedGIProduct(dealId, productId, false);
  }

  updateMissingDetails(dealId, data) {
    return this.client.post(`deal/${dealId}/complete-quickstart`, data);
  }

  rollbackStep(dealId, step) {
    return this.client.post(`deal/${dealId}/step/${step}/rollback`);
  }

  resendBouncedEmail(dealId, data) {
    return this.client.post(`deal/${dealId}/resend-documentation-email`, data);
  }

  uploadDocument(dealId, data) {
    return this.client.post(`deal/${dealId}/upload-document`, data);
  }

  archiveDeal(dealId, params) {
    return this.client.delete(`deal/${dealId}`, { params });
  }

  savePolicyReference(dealId, registrationId, data) {
    return this.client.put(`deal/${dealId}/registrations/${registrationId}`, data);
  }
}

export default new DealRepository();
