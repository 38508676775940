<template>
  <div
    :id="id"
    :class="`${fieldClass}--get-address`"
  >
    <b-input-group>
      <b-form-input
        v-model="mutableValue"
        v-bind="$attrs"
        :disabled="disabled"
        :state="state"
        trim
      />
      <template
        v-if="(anyLoading || addressesFound) && !manualEntry"
        #append
      >
        <b-input-group-text>
          <b-spinner
            v-if="anyLoading"
            small
          />
          <CollapseButton
            v-else
            v-b-tooltip.hover.left="showHideSuggestionsTooltipText"
            :collapsed="hideSuggestions"
            @click="hideSuggestions = !hideSuggestions"
          />
        </b-input-group-text>
      </template>
    </b-input-group>
    <template v-if="hasCalledLookup && !(manualEntry || hideSuggestions)">
      <b-list-group
        v-if="addressesFound"
        class="mt-2"
      >
        <b-list-group-item
          v-for="(address, index) of addresses"
          :key="`address-${index}`"
          class="px-3 py-2"
          :disabled="disabled"
          href="#"
          @click="selectAddress(address)"
          v-text="address.display_address"
        />
      </b-list-group>
      <div
        v-else
        class="mt-2 font-italic"
      >
        No addresses found.
      </div>
    </template>
    <b-link
      class="d-block mt-2"
      :disabled="disabled"
      href="#"
      @click="toggleManualEntry"
      v-text="manualEntry ? 'Show suggestions' : 'Enter address manually'"
    />
  </div>
</template>
<script>
import { fieldMixin, loadingMixin } from '@itccompliance/compliance-vue-essentials-plugin';
import postcodeValidator from '@itccompliance/compliance-vue-essentials-plugin/src/validators/postcode';
import lookupRepo from '@/api/repositories/lookup';
import { nonPostcodeAddressFieldKeys } from '@/assets/data/forms/models/address';
import CollapseButton from '@/components/global/CollapseButton.vue';

const validatePostcode = postcodeValidator.validation;

/*
  This field models the postcode value and fills out other fields in its form with values from the
  getAddress lookup result.
*/
export default {
  name: 'GetAddressField',
  components: {
    CollapseButton,
  },
  mixins: [
    fieldMixin,
    loadingMixin,
  ],
  props: {
    debounceDuration: {
      type: Number,
      default: 250,
    },
    endpointBasePath: {
      type: String,
      default: undefined,
    },
  },
  data: (vm) => {
    const formWrapper = vm.$parent;
    const nonPostcodeAddressFields = nonPostcodeAddressFieldKeys.reduce((fields, key) => {
      const field = formWrapper.fields.find((f) => [f.id, f.key].includes(key));
      if (field) fields.push(field);
      return fields;
    }, []);
    return {
      addresses: [],
      allAddressFieldsVisible: nonPostcodeAddressFields.every((f) => f.show),
      debounceLoadingKey: 'debounce', // show the loading spinner while request is debounced for immediate feedback
      formWrapper,
      getAddressTimeout: null,
      hasCalledLookup: false,
      hideSuggestions: false,
      loadingKey: 'get-address',
      manualEntry: false,
      nonPostcodeAddressFields,
      searchEnabled: true,
    };
  },
  computed: {
    addressesFound() {
      return this.addresses.length > 0;
    },
    canSearch() {
      return this.searchEnabled && !this.manualEntry;
    },
    showHideSuggestionsTooltipText() {
      return `${this.hideSuggestions ? 'Show' : 'Hide'} suggestions`;
    },
  },
  beforeDestroy() {
    this.clearGetAddressTimeout();
  },
  methods: {
    mutableValueWatcher(val) {
      if (validatePostcode(val)) {
        if (this.canSearch) this.getAddressesDebounced(val);
      } else this.clearAddresses();
      fieldMixin.methods.mutableValueWatcher.bind(this)(val);
    },
    clearAddresses() {
      if (this.addresses.length > 0) this.addresses = [];
    },
    clearGetAddressTimeout() {
      if (this.getAddressTimeout) {
        clearTimeout(this.getAddressTimeout);
        this.getAddressTimeout = null;
      }
    },
    getAddressesDebounced(postcode) {
      this.clearGetAddressTimeout();
      if (postcode) {
        this.setLoading(this.debounceLoadingKey);
        const getAddresses = () => {
          this.getAddresses(postcode);
          this.unsetLoading(this.debounceLoadingKey);
        };
        const { debounceDuration } = this;
        if (debounceDuration && debounceDuration > 0) {
          this.getAddressTimeout = setTimeout(getAddresses, debounceDuration);
        } else getAddresses();
      } else this.addresses = [];
    },
    async getAddresses(postcode) {
      this.setLoading(this.loadingKey);
      try {
        const response = await lookupRepo.lookupAddresses(postcode, this.endpointBasePath);
        this.addresses = response.data.data;
        this.hideSuggestions = false;
      } catch {
        this.clearAddresses();
      } finally {
        this.hasCalledLookup = true;
        this.unsetLoading(this.loadingKey);
      }
    },
    async selectAddress(address) {
      this.disableSearchForTick();
      this.nonPostcodeAddressFields.forEach((field) => {
        this.formWrapper.onInput(
          field,
          address[field.key || field.id] || null,
          { wasProgrammatic: true },
        );
      });
      this.showAllAddressFields();
      this.hideSuggestions = true;
    },
    showAllAddressFields() {
      this.nonPostcodeAddressFields.forEach((field) => {
        field.show = true;
        this.formWrapper.$forceUpdate();
        this.allAddressFieldsVisible = true;
      });
    },
    toggleManualEntry() {
      this.manualEntry = !this.manualEntry;
      if (this.manualEntry) {
        if (!this.allAddressFieldsVisible) this.showAllAddressFields();
      } else {
        const postcode = this.mutableValue;
        if (postcode && validatePostcode(postcode)) this.getAddresses(postcode);
      }
    },
    async disableSearchForTick() {
      this.searchEnabled = false;
      await this.$nextTick();
      this.searchEnabled = true;
    },
    parseInitialValue(v) {
      this.disableSearchForTick();
      return this.transformValue(v);
    },
  },
};
</script>
