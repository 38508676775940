import activity from './activity';
import documents from './documents';

const modules = {
  activity,
  documents,
};

export default {
  namespaced: true,
  modules,
  actions: {
    reset({ dispatch }) {
      Object.keys(modules).forEach((moduleKey) => {
        dispatch(`${moduleKey}/reset`);
      });
    },
  },
};
