/* eslint-disable no-underscore-dangle, default-param-last */
export default (
  h = window,
  o = document,
  t = 'https://static.hotjar.com/c/hotjar-',
  j = '.js?sv=',
  a,
  r,
) => {
  h.hj = h.hj || ((...args) => { (h.hj.q = h.hj.q || []).push(...args); });
  h._hjSettings = { hjid: process.env.VUE_APP_HOTJAR_SITE_ID || null, hjsv: 6 };
  [a] = o.getElementsByTagName('head');
  r = o.createElement('script'); r.async = 1;
  r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
  a.appendChild(r);
};
