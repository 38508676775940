/* istanbul ignore next */const Deal = () => import(/* webpackChunkName: "deal" */ '@/views/Deal.vue');
/* istanbul ignore next */const Step = () => import(/* webpackChunkName: "step" */ '@/views/deal/Step.vue');
/* istanbul ignore next */const DealDashboard = () => import(/* webpackChunkName: "dashboard" */ '@/components/deal/Dashboard.vue');

export default [
  {
    path: '/deal/:dealId',
    component: Deal,
    children: [
      {
        name: 'Deal',
        path: '',
        component: DealDashboard,
        meta: {
          title: (route) => `Deal ${route.params.dealId}`,
          requiresTraining: true,
        },
      },
      {
        path: 'step/:stepName',
        name: 'Step',
        component: Step,
        meta: {
          requiresTraining: (route) => route.params.stepName !== 'complete',
        },
      },
    ],
  },
];
