import Repository from '../Repository';

export class LookupRepository extends Repository {
  lookupVRN(vrn) {
    return this.client.get(`/vehicles/${vrn}`);
  }

  lookupLicence(dealId, licenceNumber) {
    return this.client.post(
      `deal/${dealId}/licence/find`,
      { licence_number: licenceNumber },
      { preventEventBusEvents: true },
    );
  }

  lookupAddresses(postcode, basePath = '/address') {
    return this.client.get(`${basePath}/${postcode}`);
  }
}

export default new LookupRepository();
