import legacyDocumentsRepo from '@/api/repositories/legacyDocuments';

export default {
  namespaced: true,
  actions: {
    reset() {},
    searchDocuments(context, params) {
      return legacyDocumentsRepo.searchDocuments(params);
    },
  },
};
