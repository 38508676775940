import Vue from 'vue';
import { mapGetters } from 'vuex';
import * as icons from './library';
import './index.scss';

Vue.component('MdIcon', {
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('dealer', ['isMotorcycleDealer']),
    iconMap() {
      const vehicleIcon = this.isMotorcycleDealer ? 'Motorbike' : 'CarOutline';
      return {
        account: 'AccountDetailsOutline',
        dashboard: 'ViewDashboardOutline',
        customer: 'AccountOutline',
        'part-ex': 'CompareHorizontal',
        finance: 'CalculatorVariantOutline',
        gi: 'ShieldOutline',
        'gi-requirements-check': 'ListStatus',
        vap: 'TagOutline',
        vehicle: vehicleIcon,
        'handover-customer-confirmation': 'AccountOutline',
        'handover-vehicle-confirmation': vehicleIcon,
        'handover-part-ex-confirmation': 'CompareHorizontal',
        'handover-finance-confirmation': 'CalculatorVariantOutline',
        'handover-gi-confirmation': 'ShieldOutline',
        'handover-vap-confirmation': 'TagOutline',
        'handover-confirmation': 'AccountMultipleCheckOutline',
        settings: 'CogOutline',
      };
    },
    iconName() {
      return this.iconMap[this.icon] || this.icon;
    },
  },
  render(createElement) {
    return createElement(
      'svg',
      {
        staticClass: 'md-icon',
        attrs: {
          viewBox: '0 0 24 24',
          width: '1em',
          height: '1em',
          focusable: 'false',
          role: 'img',
          'aria-label': 'icon',
          fill: 'currentcolor',
          xmlns: 'http://www.w3.org/2000/svg',
        },
      },
      [
        createElement('path', {
          attrs: {
            d: icons[`mdi${this.iconName}`],
          },
        }),
      ],
    );
  },
});
