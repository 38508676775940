<template>
  <v-select
    :id="id"
    v-model="mutableValue"
    :class="[
      `${fieldClass}--select-filterable`,
      isValidated ? `border rounded border-${borderVariant}` : null
    ]"
    :disabled="disabled"
    :options="options"
    placeholder=" - Select an option - "
    :reduce="option => option.value"
    v-bind="$attrs"
  >
    <template #no-options>
      No options found.
    </template>
  </v-select>
</template>

<script>
import vSelect from 'vue-select';
import { utils, fieldMixin } from '@itccompliance/compliance-vue-essentials-plugin';

const { deepGet } = utils.object;
const { parseObject } = utils.parsers;

export default {
  name: 'SelectFilterableField',
  components: { vSelect },
  mixins: [fieldMixin],
  computed: {
    borderVariant() {
      return this.state ? 'success' : 'danger';
    },
    options() {
      const options = parseObject(deepGet(this, 'field.options'));
      if (Array.isArray(options)) {
        return options.map((opt) => {
          if (typeof opt === 'object' && opt !== null && !Array.isArray(opt)) return opt;
          return { value: opt, label: opt };
        });
      }
      return Object.entries(options).map(([key, value]) => ({ value: key, label: value }));
    },
  },
};
</script>
