import { loadingModule, utils } from '@itccompliance/compliance-vue-essentials-plugin';
import dealRepo from '@/api/repositories/deal';
import replaceItem from '@/utils/array/replaceItem';
import EventBus from '@/services/EventBus';

const createInitialState = () => ({
  deal: null,
  loadingSteps: [],
});

const doRequestWithLoadingState = async (dispatch, requestKey, loadingKey, ...params) => {
  dispatch('loading/setLoading', loadingKey);
  try {
    const response = await dealRepo[requestKey](...params);
    return response;
  } finally {
    dispatch('loading/unsetLoading', loadingKey);
  }
};

export default {
  namespaced: true,
  modules: {
    loading: loadingModule,
  },
  state: createInitialState(),
  actions: {
    reset({ commit }) {
      commit('RESET');
    },
    async createDeal({ dispatch }, payload) {
      const { data } = await dealRepo.createDeal(payload);
      dispatch('setDeal', data.data);
      return data.data;
    },
    async disableStep({ commit, dispatch, state }, { dealId, stepName, reason }) {
      const loadingKey = `deal-${dealId}-step-${stepName}-disable`;
      const { data } = await doRequestWithLoadingState(
        dispatch,
        'disableStep',
        loadingKey,
        dealId,
        stepName,
        reason,
      );
      commit('DISABLE_STEP', stepName);
      dispatch('getDeal', state.deal.id);
      return data.data;
    },
    async enableStep({ commit, dispatch, state }, { dealId, stepName }) {
      const loadingKey = `deal-${dealId}-step-${stepName}-enable`;
      const { data } = await doRequestWithLoadingState(
        dispatch,
        'enableStep',
        loadingKey,
        dealId,
        stepName,
      );
      commit('ENABLE_STEP', stepName);
      dispatch('getDeal', state.deal.id);
      return data.data;
    },
    async fetchStep({ commit }, { dealId, stepName }) {
      commit('SET_LOADING_STEP', stepName);
      const { data } = await dealRepo.getStep(dealId, stepName);
      if (utils.object.deepGet(data, 'data.additional_data.customer_email_bounced')) {
        EventBus.$emit('show-bounced-email-modal', dealId);
      }
      commit('UNSET_LOADING_STEP', stepName);
      return data.data;
    },
    async getDeal({ dispatch }, dealId) {
      const loadingKey = `deal-${dealId}`;
      const { data } = await doRequestWithLoadingState(dispatch, 'getDeal', loadingKey, dealId);
      if (utils.object.deepGet(data, 'data.customer.customer_email_bounced')) {
        EventBus.$emit('show-bounced-email-modal', dealId);
      }
      dispatch('setDeal', data.data);
      return data.data;
    },
    replaceDealStep({ commit, getters }, step) {
      const existingStep = getters.getStep(step.key);
      if (existingStep) {
        const index = getters.deal.steps.indexOf(existingStep);
        if (index > -1) commit('REPLACE_DEAL_STEP', { index, step });
      }
    },
    async saveStep({ dispatch }, { dealId, stepName, data }) {
      const response = await dealRepo.saveStep(dealId, stepName, data);
      const step = response.data.data;
      if (step) dispatch('replaceDealStep', step);
      return step;
    },
    async verifyStep({ state, dispatch }, stepName) {
      const loadingKey = `deal-${state.deal.id}-step-${stepName}-verify`;
      const { data } = await doRequestWithLoadingState(
        dispatch,
        'verifyStep',
        loadingKey,
        state.deal.id,
        stepName,
      );
      const step = data.data;
      dispatch('getDeal', state.deal.id);
      return step;
    },
    async refuteStep({ dispatch, state }, stepName) {
      const loadingKey = `deal-${state.deal.id}-step-${stepName}-refute`;
      const { data } = await doRequestWithLoadingState(
        dispatch,
        'refuteStep',
        loadingKey,
        state.deal.id,
        stepName,
      );
      const step = data.data;
      return step;
    },
    async completeCurrentPhase({ dispatch, getters }) {
      const loadingKey = `deal-${getters.deal.id}-phase-${getters.currentPhase.name}-complete`;
      await doRequestWithLoadingState(dispatch, 'completeCurrentPhase', loadingKey, getters.deal.id);
      dispatch('getDeal', getters.deal.id);
    },
    async requestEmailVerification({ state }, emailAddress) {
      await dealRepo.requestEmailVerification(state.deal.id, emailAddress);
    },
    async verifyEmail(_, { dealId, token }) {
      await dealRepo.verifyEmail(dealId, token);
    },
    setDeal({ commit, dispatch }, deal) {
      commit('SET_DEAL', deal);
      dispatch('sidebar/reset', null, { root: true });
    },
    async changeApproach({ commit, getters }, { approach, dealId }) {
      await dealRepo.changeApproach(dealId || getters.deal.id, approach);
      if (getters.deal) commit('SET_DEAL_APPROACH', approach);
    },
    getDemandsAndNeeds(context, dealId) {
      return dealRepo.getDemandsAndNeeds(dealId);
    },
    completeDemandsAndNeeds(context, { dealId, signatures }) {
      return dealRepo.completeDemandsAndNeeds(dealId, signatures);
    },
    searchDeals(context, params) {
      return dealRepo.searchDeals(params);
    },
    searchGroupDeals(context, params) {
      return dealRepo.searchGroupDeals(params);
    },
    registerPolicies({ getters }, data) {
      return dealRepo.registerPolicies(getters.deal.id, data);
    },
    getPolicyRegistrationStatus({ getters }, registrationReference) {
      return dealRepo.getPolicyRegistrationStatus(getters.deal.id, registrationReference);
    },
    concludeMoratoriumEarly({ getters }, { dealId, productId }) {
      return dealRepo.concludeMoratoriumEarly(dealId || getters.deal.id, productId);
    },
    createCustomerSupportForm(context, data) {
      return dealRepo.createCustomerSupportForm(data);
    },
    ...['confirm', 'unconfirm'].reduce((obj, prefix) => {
      const fnKey = `${prefix}GIProduct`;
      obj[fnKey] = async ({ getters }, { dealId, productId }) => {
        const response = await dealRepo[fnKey](dealId || getters.deal.id, productId);
        const products = response.data.data;
        return products;
      };
      return obj;
    }, {}),
    ...['decided', 'undecided'].reduce((obj, prefix) => {
      const fnKey = `${prefix}GIProduct`;
      obj[fnKey] = async ({ getters }, { dealId, productId }) => {
        const response = await dealRepo[fnKey](dealId || getters.deal.id, productId);
        const products = response.data.data;
        return products;
      };
      return obj;
    }, {}),
    updateMissingDetails({ getters }, { dealId, data }) {
      return dealRepo.updateMissingDetails(dealId || getters.deal.id, data);
    },
    rollbackStep(context, { dealId, stepName }) {
      return dealRepo.rollbackStep(dealId, stepName);
    },
    resendBouncedEmail(context, { dealId, data }) {
      return dealRepo.resendBouncedEmail(dealId, data);
    },
    uploadDocument({ getters }, { dealId, data }) {
      return dealRepo.uploadDocument(dealId || getters.deal.id, data);
    },
    archiveDeal(context, { dealId, data }) {
      return dealRepo.archiveDeal(dealId, data);
    },
    savePolicyReference(context, { dealId, registrationId, data }) {
      return dealRepo.savePolicyReference(dealId, registrationId, data);
    },
  },
  getters: {
    ...utils.vuex.mapSimpleGetters(['deal']),
    canCompletePhase: (state, { deal }) => (phase) => deal && deal.steps
      .filter((s) => s.phase === phase)
      .every((s) => s.verified || !s.enabled),
    currentPhase: (state, { deal }) => deal && {
      name: deal.current_phase,
      steps: deal.steps.filter((s) => s.phase === deal.current_phase),
    },
    customer: (state, { getStep }) => {
      try {
        return getStep('customer').additional_data.customer;
      } catch {
        return null;
      }
    },
    canRefuteSteps: (state, { deal, getStep }) => {
      if (!deal) return false;
      if (deal.current_phase !== 'handover') return true;
      const handoverFinanceComplete = getStep('handover-finance-confirmation')?.complete;
      const hasRegisteredProducts = getStep('handover-gi-confirmation')?.additional_data?.has_registered_products;
      return !(handoverFinanceComplete || hasRegisteredProducts);
    },
    getStep: (state, { deal }) => (stepKey) => deal && deal.steps.find((s) => s.key === stepKey),
    isLoadingStep: ({ loadingSteps }) => loadingSteps.length > 0,
    isStepPending: () => (step) => !!step.pending_message,
    isUpdatingDeal: (state, getters) => getters['loading/anyLoading'],
    vehicle: (state, { getStep }) => {
      try {
        return getStep('vehicle').additional_data;
      } catch {
        return null;
      }
    },
  },
  mutations: {
    ...utils.vuex.mapSimpleMutations(['deal']),
    RESET(state) {
      Object.assign(state, createInitialState());
    },
    ENABLE_STEP(state, stepName) {
      state.deal.steps.find((s) => s.key === stepName).enabled = true;
    },
    DISABLE_STEP(state, stepName) {
      state.deal.steps.find((s) => s.key === stepName).enabled = false;
    },
    REPLACE_DEAL_STEP(state, { index, step }) {
      state.deal.steps = replaceItem(state.deal.steps, index, step);
    },
    SET_DEAL_APPROACH(state, approach) {
      state.deal.approach = approach;
    },
    SET_LOADING_STEP(state, stepName) {
      if (!state.loadingSteps.includes(stepName)) state.loadingSteps.push(stepName);
    },
    UNSET_LOADING_STEP(state, stepName) {
      const index = state.loadingSteps.indexOf(stepName);
      if (index > -1) state.loadingSteps.splice(index, 1);
    },
  },
};
