import Vue from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import * as icons from './library';

library.add(...Object.values(icons));

Vue.component('FaIcon', FontAwesomeIcon);
Vue.component('FaLayers', FontAwesomeLayers);
