<template>
  <a
    :href="href"
    rel="noopener noreferrer"
    target="_blank"
  >
    <img
      :alt="`${platform} app store`"
      class="app-store-badge m-2"
      :src="imgSrc"
    >
  </a>
</template>

<script>
/* eslint-disable global-require */
const PLATFORMS = Object.freeze({
  apple: {
    badge: require('@/assets/img/branding/app_store_badge.svg'),
    baseUrl: 'https://apps.apple.com/gb/app/',
  },
  google: {
    badge: require('@/assets/img/branding/google_play_badge.png'),
    baseUrl: 'https://play.google.com/store/apps/details?id=',
  },
});
/* eslint-enable global-require */

export default {
  props: {
    appId: {
      type: String,
      required: true,
    },
    platform: {
      type: String,
      required: true,
      validator: (v) => Object.keys(PLATFORMS).includes(v),
    },
  },
  data: () => ({
    PLATFORMS,
  }),
  computed: {
    href() {
      return `${PLATFORMS[this.platform].baseUrl}${this.appId}`;
    },
    imgSrc() {
      return PLATFORMS[this.platform].badge;
    },
  },
};
</script>
