const createInitialState = () => ({
  buttons: [],
});

export default {
  namespaced: true,
  state: createInitialState(),
  getters: {
    buttons: ({ buttons }) => buttons,
    getButton: ({ buttons }) => (key) => buttons.find((b) => b.key === key),
  },
  mutations: {
    ADD_BUTTON(state, button) {
      state.buttons.push(button);
    },
    REMOVE_BUTTON(state, index) {
      state.buttons.splice(index, 1);
    },
    RESET(state) {
      Object.assign(state, createInitialState());
    },
  },
  actions: {
    addButton({ commit, getters }, button) {
      const requiredProperties = ['key', 'text'];
      if (!button || typeof button !== 'object' || !requiredProperties.every((k) => k in button)) {
        console.error(`Invalid button object provided - must have properties '${requiredProperties.join("', '")}'.`);
      } else {
        const existing = getters.getButton(button.key);
        if (!existing) {
          if (!('loading' in button)) button.loading = false;
          commit('ADD_BUTTON', button);
        }
      }
    },
    removeButton({ commit, getters }, button) {
      const key = button && typeof button === 'object' ? button.key : button;
      const index = getters.buttons.findIndex((b) => b.key === key);
      if (index >= 0) commit('REMOVE_BUTTON', index);
    },
    reset({ commit }) {
      commit('RESET');
    },
  },
};
