<template>
  <input
    v-model="mutableValue"
    type="hidden"
  >
</template>
<script>
import { fieldMixin } from '@itccompliance/compliance-vue-essentials-plugin';

export default {
  name: 'HiddenField',
  mixins: [fieldMixin],
};
</script>
