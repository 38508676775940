<template>
  <div
    :id="id"
    :class="`${fieldClass}--year`"
  >
    <b-form-input
      v-model="mutableYear"
      v-bind="$attrs"
      :disabled="disabled"
      :state="stateComputed"
      step="1"
      type="number"
    />
    <b-form-invalid-feedback
      :state="stateComputed"
      v-text="invalidFeedback"
    />
  </div>
</template>

<script>
import { fieldMixin } from '@itccompliance/compliance-vue-essentials-plugin';

const currentYear = new Date().getFullYear();

export default {
  name: 'YearField',
  mixins: [
    fieldMixin,
  ],
  props: {
    max: {
      type: Number,
      default: null,
    },
    maxRelative: {
      type: Number,
      default: null,
    },
    min: {
      type: Number,
      default: null,
    },
    minRelative: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    mutableYear: null,
  }),
  computed: {
    isWithinLimits() {
      const {
        mutableYear,
        maxComputed,
        minComputed,
      } = this;
      return mutableYear
        && mutableYear >= minComputed
        && mutableYear <= maxComputed;
    },
    internalState() {
      return this.isWithinLimits;
    },
    invalidFeedback() {
      if (this.internalState === false) {
        if (this.isWithinLimits === false) {
          const { maxComputed, minComputed } = this;
          if (maxComputed && minComputed) return `Must be between ${minComputed} and ${maxComputed} (inclusive)`;
          if (maxComputed) return `Must be ${maxComputed} or before`;
          if (minComputed) return `Must be ${minComputed} or after`;
        }
      }
      return null;
    },
    maxComputed() {
      const { max, maxRelative } = this;
      if (max) return max;
      if (maxRelative) return currentYear + maxRelative;
      return null;
    },
    minComputed() {
      const { min, minRelative } = this;
      if (min) return min;
      if (minRelative) return currentYear + minRelative;
      return null;
    },
    stateComputed() {
      if ([this.state, this.internalState].includes(false)) return false;
      return this.state;
    },
    stateVariant() {
      switch (this.stateComputed) {
        case true:
          return 'success';
        case false:
          return 'danger';
        default:
          return 'primary';
      }
    },
  },
  watch: {
    mutableYear: {
      immediate: false,
      deep: true,
      async handler(year) {
        const yearAsNumber = Number.parseFloat(year);
        const yearAsInt = Math.floor(yearAsNumber);
        if (this.internalState) this.mutableValue = yearAsInt;
        else this.mutableValue = null;
        if (year !== yearAsInt) {
          await this.$nextTick();
          this.mutableYear = yearAsInt; // Remove any decimal points from value
        }
      },
    },
  },
  methods: {
    parseInitialValue(value) {
      return this.transformValue(value);
    },
    transformValue(value) {
      if (value) this.mutableYear = value;
      return value;
    },
  },
};
</script>
