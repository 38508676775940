var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',{staticClass:"licence-image"},[_c('b-col',[_c('img',{staticClass:"d-block mx-auto w-100",class:`licence-image__image${_vm.hidden ? '--hidden' : ''}`,attrs:{"alt":"Licence","src":_vm.src}}),_c('div',{class:[
        'licence-image__visibility-toggle',
        'position-absolute',
        'cursor-pointer',
        'd-flex',
        'align-items-center',
        'mr-3',
        'bg-white',
        'px-2',
        'py-1',
        'rounded',
      ],on:{"click":function($event){_vm.hidden = !_vm.hidden},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.hidden = !_vm.hidden}}},[_c('small',[_vm._v(_vm._s(_vm.hidden ? 'Show' : 'Hide')+" licence")]),_c('b-icon',{staticClass:"h5 my-0 ml-1",attrs:{"icon":`eye${_vm.hidden ? '' : '-slash'}`}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }