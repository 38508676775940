<template>
  <b-form-file
    v-model="mutableValue"
    :class="`${fieldClass}--file`"
    :disabled="disabled"
    :state="state"
    v-bind="{
      accept: 'application/pdf',
      'drop-placeholder': 'Drop file here...',
      placeholder: 'Choose a file or drop it here...',
      ...$attrs
    }"
  />
</template>

<script>
import { fieldMixin } from '@itccompliance/compliance-vue-essentials-plugin';

export default {
  name: 'FileField',
  mixins: [fieldMixin],
};
</script>
