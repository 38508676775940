import Repository from '../Repository';

export class AgreementsRepository extends Repository {
  getAgreements() {
    return this.client.get('/platform-agreements');
  }

  saveAgreements(answers) {
    return this.client.post('/platform-agreements', { answers });
  }
}

export default new AgreementsRepository();
