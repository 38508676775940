/* istanbul ignore next */const DealerSelect = () => import(/* webpackChunkName: "auth" */ '@/views/auth/DealerSelect.vue');

export default [
  {
    path: '/dealer-select',
    name: 'dealer-select',
    component: DealerSelect,
    meta: {
      title: 'Select Dealer',
      noDealerRequired: true,
    },
  },
];
