/* istanbul ignore next */const Complaints = () => import(/* webpackChunkName: "complaints" */ '@/views/Complaints.vue');
/* istanbul ignore next */const SubmitComplaints = () => import(/* webpackChunkName: "submit-complaints" */ '@/components/complaints/SubmitComplaints.vue');
/* istanbul ignore next */const ViewSubmittedComplaints = () => import(/* webpackChunkName: "view-submitted-complaints" */ '@/components/complaints/ViewComplaints.vue');

export default [
  {
    path: '/complaints',
    component: Complaints,
    children: [
      {
        name: 'complaints',
        path: '',
        component: SubmitComplaints,
        meta: {
          title: 'Complaints',
          permissionRequirements: {
            hasPermission: 'complaints',
          },
        },
      },
      {
        name: 'view-submitted-complaints',
        path: 'view-submitted-complaints',
        component: ViewSubmittedComplaints,
        meta: {
          title: ['View Submitted Complaints', 'Complaints'],
          permissionRequirements: {
            hasPermission: 'complaints',
          },
        },
      },
    ],
  },
];
