import Repository from '../Repository';

export class VehicleRepository extends Repository {
  getManufacturers(source = 'legacy', search = undefined) {
    return this.client.get('vehicle-make', {
      params: {
        name: search,
        source,
      },
    });
  }

  getManufacturerModels(manufacturerId, source = 'legacy', search = undefined) {
    return this.client.get(`vehicle-make/${manufacturerId}/models`, {
      params: {
        name: search,
        source,
      },
    });
  }
}

export default new VehicleRepository();
