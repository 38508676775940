export default (...args) => {
  // eslint-disable-next-line no-nested-ternary
  const parts = args.length === 1
    ? Array.isArray(args[0])
      ? args[0]
      : [args[0]]
    : args;
  const partsTruthy = parts.filter((p) => !!p);
  const baseTitle = 'Apex';
  if (partsTruthy.length) return `${partsTruthy.join(' - ')} | ${baseTitle}`;
  return baseTitle;
};
