<template>
  <div
    id="app-window"
    class="bg-light vw-100"
  >
    <b-overlay
      class="d-lg-none vw-100 vh-100 print-hide"
      show
    >
      <b-card
        slot="overlay"
      >
        <b-iconstack
          width="3em"
          height="3em"
          style="vertical-align: middle;"
          class="mr-3"
        >
          <b-icon
            icon="arrow-clockwise"
          />
          <b-icon
            icon="tablet-fill"
            scale="0.4"
          />
        </b-iconstack>
        <span>Your screen is too small</span>
      </b-card>
    </b-overlay>
    <UserMenu v-if="!isLoading('meta')" />
    <b-overlay
      v-if="isLoading('meta') || (isLoading('theme') && !hasLoadedThemeOnce)"
      class="app-loading-mask position-fixed vw-100 vh-100"
      opacity="1"
      show
    />
    <template v-else>
      <router-view :key="currentDealerId" />
      <AnalyticsConsent />
    </template>
    <compliance-prompt-container />
  </div>
</template>
<script>
import { loadingMixin, utils } from '@itccompliance/compliance-vue-essentials-plugin';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import EventBus from '@/services/EventBus';
import AgreementsForm from '@/components/global/AgreementsForm.vue';
import AnalyticsConsent from '@/components/global/AnalyticsConsent.vue';
import UserMenu from '@/components/global/UserMenu.vue';
import formatDocumentTitle from '@/utils/string/formatDocumentTitle';
import bouncedEmailFormModel from '@/assets/data/forms/models/deal/bouncedEmail';
import { checkPermissions } from '@/utils/permissions';

export default {
  components: {
    AnalyticsConsent,
    UserMenu,
  },
  mixins: [
    loadingMixin,
  ],
  data: () => ({
    hasLoadedThemeOnce: false,
  }),
  computed: {
    ...mapGetters(['meta']),
    ...mapGetters('dealer', ['currentDealerId']),
    ...mapGetters('compliancePrompt', ['prompts']),
    shouldLoadDealerTheme() {
      return !!(this.currentDealerId && !this.$route.meta.noLoginRequired);
    },
  },
  watch: {
    currentDealerId: {
      immediate: true,
      handler(currentDealerId) {
        if (!checkPermissions(this.$route)) {
          this.$router.replace({ name: 'dashboard' });
        }
        if (this.shouldLoadDealerTheme) {
          this.applyDealerTheme(currentDealerId);
        }
      },
    },
    shouldLoadDealerTheme: {
      immediate: false,
      handler(shouldLoadDealerTheme) {
        if (shouldLoadDealerTheme) {
          this.applyDealerTheme(this.currentDealerId);
        }
      },
    },
    $route: {
      immediate: true,
      deep: true,
      handler(route, oldRoute) {
        if (
          !oldRoute
          || route.path !== oldRoute.path
          || [route.path, oldRoute.path].every((path) => path === '/')
        ) {
          const title = route && route.meta && route.meta.title;
          const titleParts = typeof title === 'function' ? title(route) : title;
          document.title = formatDocumentTitle(titleParts);
        }
        const { deepGet } = utils.object;
        if (
          deepGet(route, 'matched.length')
          && !deepGet(route, 'meta.noLoginRequired')
          && !deepGet(this, 'meta.dealers')
        ) this.doGetMeta();
      },
    },
  },
  created() {
    EventBus.$on('api-error', (error) => {
      const message = utils.api.formatErrorMessage(error);
      const title = error.title || 'Error';
      this.$showToast(message, { title });
      const { dealId } = this.$route.params || null;
      this.saveErrorMessage({
        message, title, time: new Date(), dealId,
      });
    });
    EventBus.$on('logout', async () => {
      this.logout();
    });
    EventBus.$on('update:userTraining', this.setUserTraining);
    EventBus.$on('update:permissions', this.setPermissions);
    EventBus.$on('update:currentDealerId', this.setCurrentDealerId);
    EventBus.$on('show-agreements-modal', () => {
      const promptId = 'agreements';
      this.$compliancePrompt.prompt({
        id: promptId,
        title: 'Platform Agreements',
        component: { render: (h) => h(AgreementsForm, { props: { promptId } }) },
        modalProps: {
          'hide-footer': true,
        },
      });
    });
    EventBus.$on('show-bounced-email-modal', (requestDealId) => {
      const routeDealId = this.$route.params.dealId;
      const dealId = requestDealId || routeDealId;
      const currentRouteIsNotDeal = !routeDealId; // error can occur on resuming deal from dashboard
      const promptId = 'bounced-email';
      if (!this.prompts.find((p) => p.id === promptId)) {
        this.$compliancePrompt.prompt({
          id: promptId,
          title: 'Resend Documentation Email',
          formModel: bouncedEmailFormModel(),
          canCancel: currentRouteIsNotDeal,
          okText: 'Save & Resend',
          beforeResolve: async (result) => {
            if (result) await this.resendBouncedEmail({ dealId, data: { email: result.email } });
          },
        });
      }
    });
    this.mountRecaptcha();
  },
  methods: {
    ...mapActions(['getMeta']),
    ...mapActions('auth', ['logout']),
    ...mapActions('deal', ['resendBouncedEmail']),
    ...mapActions('dealer', ['getInitialData']),
    ...mapActions('theme', ['getDealerTheme']),
    ...mapActions('recaptcha', ['mountRecaptcha']),
    ...mapActions('profile', ['setUserTraining']),
    ...mapMutations('auth', {
      setPermissions: 'SET_PERMISSIONS',
    }),
    ...mapMutations('dealer', { setCurrentDealerId: 'SET_CURRENT_DEALER_ID' }),
    ...mapMutations('error', { saveErrorMessage: 'SAVE_ERROR_MESSAGE' }),
    async doGetMeta() {
      this.setLoading('meta');
      try {
        await this.getMeta();
      } catch {
        this.logout();
      } finally {
        this.unsetLoading('meta');
      }
    },
    async applyDealerTheme(dealerId) {
      const loadingKey = 'theme';
      if (!this.isLoading(loadingKey)) {
        this.setLoading(loadingKey);
        this.removeDealerTheme();
        const css = await this.getDealerTheme(dealerId);
        if (css) {
          const el = document.createElement('style');
          el.id = 'dealer-theme';
          el.innerText = css;
          document.head.appendChild(el);
        }
        this.unsetLoading(loadingKey);
        this.hasLoadedThemeOnce = true;
      }
    },
    removeDealerTheme() {
      const styleEl = document.getElementById('dealer-theme');
      if (styleEl) styleEl.parentNode.removeChild(styleEl);
    },
  },
};
</script>

<style lang="scss" src="@/scss/_custom.scss"></style>
