export default {
  methods: {
    /**
     * Shows an alert popup in the form of a Bootstrap [Toast](https://bootstrap-vue.org/docs/components/toast)
     * @param {String} message The message to show in the alert body
     * @param {Object} options [Toast component props](https://bootstrap-vue.org/docs/components/toast#comp-ref-b-toast-props)
     * @param {Number} showDelay Milliseconds to wait before showing the toast. This is useful for
     * creating toasts from a component that is being destroyed as the timeout continues to exist.
     */
    $showToast(message = 'An unknown error occurred.', options = {}, showDelay = 0) {
      const config = {
        autoHideDelay: 30000,
        title: 'Error',
        variant: 'danger',
        ...options,
      };
      const showToast = () => this.$bvToast.toast(message, config);
      if (showDelay > 0) setTimeout(() => showToast, showDelay);
      else showToast();
    },
  },
};
