<template>
  <div>
    <RadioField
      v-bind="$attrs"
      v-on="$listeners"
    />
    <div v-if="subtext">
      <small v-text="subtext" />
    </div>
  </div>
</template>

<script>
import RadioField from '@itccompliance/compliance-vue-essentials-plugin/src/components/bootstrap-vue/form/fields/radio.vue';

export default {
  name: 'RadioSubtextField',
  components: {
    RadioField,
  },
  props: {
    subtext: {
      type: String,
      default: null,
    },
  },
};
</script>
