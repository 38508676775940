import axios from 'axios';
import qs from 'qs';
import EventBus from '@/services/EventBus';
import { utils } from '@itccompliance/compliance-vue-essentials-plugin';

const getBaseUrl = (apiName = '') => {
  let str = 'VUE_APP_API_';
  if ((typeof apiName === 'string') && (apiName.length > 0)) str += `${apiName.replace('-', '_')}_`;
  str += 'URL';
  return str;
};

export default (apiName) => {
  axios.defaults.paramsSerializer = (params) => qs.stringify(params, { arrayFormat: 'indices' });
  axios.defaults.withCredentials = true;
  ['post', 'put', 'patch'].forEach((verb) => {
    axios.defaults.headers[verb]['Content-Type'] = 'application/json';
  });
  const instance = axios.create({ timeout: 0 });
  instance.defaults.baseURL = process.env[getBaseUrl(apiName)];
  instance.interceptors.response.use(
    (res) => {
      const metaUpdateEvents = {
        current_dealer_id: 'currentDealerId',
        permissions: 'permissions',
        user_training: 'userTraining',
      };
      Object.entries(metaUpdateEvents).forEach(([metaKey, eventKey]) => {
        const data = res?.data?.meta?.[metaKey];
        if (data) EventBus.$emit(`update:${eventKey}`, data);
      });
      return res;
    },
    (error) => {
      switch (utils.object.deepGet(error, 'response.status')) {
        case 401:
          if (!utils.object.deepGet(error, 'config.preventEventBusLogoutEventOn401')) {
            window.location.href = '/oauth/login';
          }
          break;
        default:
      }
      switch (utils.object.deepGet(error, 'response.data.code')) {
        case 'unanswered_questions':
          EventBus.$emit('show-agreements-modal');
          utils.object.deepSet(error, 'config.preventEventBusEvents', true);
          break;
        case 'customer_email_bounce':
        case 'customer_email_issue': {
          let requestDealId = null;
          try {
            requestDealId = error.config.url.match(/deal\/(?<dealId>\d+)/).groups.dealId;
          } finally {
            EventBus.$emit('show-bounced-email-modal', requestDealId);
            utils.object.deepSet(error, 'config.preventEventBusEvents', true);
          }
          break;
        }
        default:
      }
      if (!utils.object.deepGet(error, 'config.preventEventBusEvents')) {
        EventBus.$emit('api-error', utils.object.deepGet(error, 'response.data'));
      }
      return Promise.reject(error);
    },
  );
  return instance;
};
