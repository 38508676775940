<template>
  <b-link
    class="collapse-button"
    href="#"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <CollapseIcon
      :class="textVariantClass"
      :collapsed="collapsed"
    />
  </b-link>
</template>

<script>
import CollapseIcon from './CollapseIcon.vue';

export default {
  components: {
    CollapseIcon,
  },
  props: {
    collapsed: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
      default: 'body',
    },
  },
  computed: {
    textVariantClass() {
      return `text-${this.variant}`;
    },
  },
};
</script>
