import parseISO from 'date-fns/parseISO';
import isBefore from 'date-fns/isBefore';
import dateFilter from '@/filters/date';

export default {
  validation: (params, field, formData, fields) => (val) => {
    if (!val) return true;
    const [targetFieldKey] = params;
    const { id: targetFieldId } = fields.find((f) => f.key === targetFieldKey);
    const targetFieldValue = formData[targetFieldId];
    if (!targetFieldValue) return true;
    return isBefore(parseISO(val), parseISO(targetFieldValue));
  },
  message: (params, field, formData, fields) => {
    const [targetFieldKey, formatString] = params;
    const { id: targetFieldId } = fields.find((f) => f.key === targetFieldKey);
    const targetFieldValue = formData[targetFieldId];
    return `Must be before ${dateFilter(targetFieldValue, formatString)}`;
  },
};
