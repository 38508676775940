import Fallback from '@/views/Fallback.vue';
import authRoutes from './auth';
import dealRoutes from './deal';
import fandiRoutes from './fandi';
import registrationRoutes from './registration';
import reportRoutes from './reports';
import complaintsRoutes from './complaints';

/* istanbul ignore next */const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue');
/* istanbul ignore next */const QuickStart = () => import(/* webpackChunkName: "quickstart" */ '@/views/QuickStart.vue');
/* istanbul ignore next */const SupportSignposting = () => import(/* webpackChunkName: "quickstart" */ '@/views/SupportSignposting.vue');
/* istanbul ignore next */const EmailVerify = () => import(/* webpackChunkName: "email" */ '@/views/EmailVerification.vue');
/* istanbul ignore next */const FriendsAndFamily = () => import(/* webpackChunkName: "fandf" */ '@/views/FriendsAndFamily.vue');
/* istanbul ignore next */const WhistleBlowing = () => import(/* webpackChunkName: "whistle-blowing" */ '@/views/WhistleBlowing.vue');
/* istanbul ignore next */const Documents = () => import(/* webpackChunkName: "documents" */ '@/views/Documents.vue');
/* istanbul ignore next */const PublicDocuments = () => import(/* webpackChunkName: "public-document" */ '@/views/PublicDocuments.vue');
/* istanbul ignore next */const StaffList = () => import(/* webpackChunkName: "staff-list" */ '@/views/StaffList.vue');
/* istanbul ignore next */const LegacyDocuments = () => import(/* webpackChunkName: "legacy-documents" */ '@/views/LegacyDocuments.vue');

export default [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      title: 'Dashboard',
    },
  },
  {
    path: '/quick-start',
    name: 'quick-start',
    component: QuickStart,
    meta: {
      title: 'Quick Start Customer Registration',
      requiresTraining: true,
    },
  },
  {
    path: '/support-signposting',
    name: 'support-signposting',
    component: SupportSignposting,
    meta: {
      title: 'Support Signposting',
      noLoginRequired: true,
    },
  },
  {
    path: '/verify-email/:dealId/:token',
    name: 'verify-email',
    component: EmailVerify,
    meta: {
      title: 'Verify your email address',
      noLoginRequired: true,
    },
  },
  {
    path: '/friends-and-family',
    name: 'f-and-f',
    component: FriendsAndFamily,
    meta: {
      title: 'Friends and Family',
    },
  },
  {
    path: '/whistle-blowing',
    name: 'whistle-blowing',
    component: WhistleBlowing,
    meta: {
      title: 'Whistle Blowing',
    },
  },
  {
    path: '/documents',
    name: 'documents',
    component: Documents,
    meta: {
      title: 'Documents',
    },
  },
  {
    path: '/public-documents',
    name: 'public-documents',
    component: PublicDocuments,
    meta: {
      title: 'Public Documents',
      noLoginRequired: true,
    },
  },
  {
    path: '/staff-list',
    name: 'staff-list',
    component: StaffList,
    meta: {
      title: 'Staff List',
      permissionRequirements: {
        hasPermission: 'staff-list',
      },
    },
  },
  {
    path: '/legacy-documents',
    name: 'legacy-documents',
    component: LegacyDocuments,
    meta: {
      title: 'Legacy Documents',
      permissionRequirements: {
        hasPermission: 'documents.legacy',
      },
    },
  },
  ...complaintsRoutes,
  ...authRoutes,
  ...dealRoutes,
  ...fandiRoutes,
  ...registrationRoutes,
  ...reportRoutes,
  {
    path: '*',
    name: '404',
    component: Fallback,
    meta: {
      noDealerRequired: true,
      noLoginRequired: true,
      title: '404',
    },
  },
];
