<template>
  <div>
    <b-list-group class="mb-3">
      <b-list-group-item :href="learningUrl">
        <span class="h5 m-0">
          Click here to view and complete the training
          <BIconArrowRight />
        </span>
      </b-list-group-item>
      <b-list-group-item :href="faqUrl">
        <span class="h5 m-0">
          Click here to read the FAQs
          <BIconArrowRight />
        </span>
      </b-list-group-item>
    </b-list-group>
    <b-list-group>
      <b-list-group-item>
        <div class="h5 mb-2 text-primary">
          Contact the office
        </div>
        <div>
          <strong>Phone:</strong>
          <a
            class="ml-1"
            href="tel:0845 177 2266"
          >0845 177 2266</a>
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { BIconArrowRight } from 'bootstrap-vue';

export default {
  components: {
    BIconArrowRight,
  },
  computed: {
    faqUrl() {
      return `${window.location.origin}/welcome#faq`;
    },
    learningUrl() {
      return `${process.env.VUE_APP_APEX_CLASSIC_URL}/learning/`;
    },
  },
};
</script>
