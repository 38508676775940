import whistleBlowingRepo from '@/api/repositories/whistleBlowing';

export default {
  namespaced: true,
  actions: {
    sendReport(context, data) {
      return whistleBlowingRepo.sendReport(data);
    },
    reset() { /*  */ },
  },
};
