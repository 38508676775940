<template>
  <div
    :id="id"
    :class="`${fieldClass}--checkbox-group-buttons`"
    :disabled="disabled"
  >
    <b-row>
      <b-col
        v-for="(option, index) of options"
        :key="option.value"
        :class="{ 'mt-4': isNotFirstRow(index) }"
        :cols="colspan"
      >
        <b-btn
          class="rounded w-100 h-100 text-left d-flex flex-column justify-content-between"
          size="lg"
          :variant="getOptionVariant(option)"
          @click="toggleOptionSelected(option)"
        >
          <div v-text="option.text" />
          <BIconCheckCircle
            class="text-success-contrast d-block ml-auto"
            :class="{ invisible: !isOptionSelected(option) }"
          />
        </b-btn>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { fieldMixin, utils } from '@itccompliance/compliance-vue-essentials-plugin';
import { BIconCheckCircle } from 'bootstrap-vue';

export default {
  name: 'CheckboxGroupButtonsField',
  components: {
    BIconCheckCircle,
  },
  mixins: [fieldMixin],
  props: {
    colspan: {
      type: Number,
      default: 4,
      validator: (v) => v > 0 && v < 13 && v % 1 === 0,
    },
  },
  computed: {
    options() {
      const options = utils.parsers.parseObject(utils.object.deepGet(this, 'field.options'));
      if (Array.isArray(options)) return options;
      return Object.entries(options).map(([key, value]) => ({ value: key, text: value }));
    },
  },
  methods: {
    transformValue(value) {
      return utils.parsers.parseArray(value);
    },
    parseInitialValue(value) {
      return this.transformValue(value);
    },
    isNotFirstRow(columnIndex) {
      return columnIndex > ((12 / this.colspan) - 1);
    },
    isOptionSelected(option) {
      const { mutableValue } = this;
      return Array.isArray(mutableValue) && mutableValue.includes(option.value);
    },
    getOptionVariant(option) {
      const { state } = this;
      let variant = state === false ? 'danger' : 'primary';
      const isSelected = this.isOptionSelected(option);
      if (isSelected) {
        if (state) variant = 'success';
      } else variant = `outline-${variant}`;
      return variant;
    },
    toggleOptionSelected(option) {
      const { value } = option;
      const { mutableValue } = this;
      if (Array.isArray(mutableValue)) {
        const valueIndex = mutableValue.indexOf(value);
        if (valueIndex > -1) {
          this.mutableValue = [
            ...mutableValue.slice(0, valueIndex),
            ...mutableValue.slice(valueIndex + 1),
          ];
        } else this.mutableValue = [...mutableValue, value];
      } else this.mutableValue = [value];
    },
  },
};
</script>
