<template>
  <div
    :id="id"
    :class="`${fieldClass}--select-with-other`"
  >
    <b-form-select
      v-bind="$attrs"
      :disabled="disabled"
      :options="options"
      :state="state"
      :value="selectedOptionValue"
      @change="onOptionChange"
    />
    <template v-if="isOther">
      <small>Please detail</small>
      <b-form-input
        v-model="otherValue"
        :disabled="disabled"
        :state="state"
      />
    </template>
  </div>
</template>

<script>
import { fieldMixin, utils } from '@itccompliance/compliance-vue-essentials-plugin';

const { deepGet } = utils.object;
const { parseObject } = utils.parsers;

const otherOptionValue = 'other';
const isOther = (v) => v === otherOptionValue;

export default {
  name: 'SelectWithOtherField',
  mixins: [fieldMixin],
  data: () => ({
    otherValue: '',
  }),
  computed: {
    isOther() {
      return isOther(this.selectedOptionValue);
    },
    options() {
      const options = parseObject(deepGet(this, 'field.options') || deepGet(this, 'field.display_attributes.options'));
      let parsedOptions = [];
      if (Array.isArray(options)) {
        parsedOptions = options.map((option) => {
          if (typeof option === 'object') return option;
          return { value: option, text: option };
        });
      } else {
        parsedOptions = Object.keys(options).map((key) => ({ value: key, text: options[key] }));
      }
      return [
        { value: null, text: ' - Select an option - ' },
        ...parsedOptions,
        { value: otherOptionValue, text: 'Other' },
      ];
    },
    selectedOptionValue() {
      return this.getSelectedOptionValue(this.mutableValue);
    },
  },
  watch: {
    otherValue: {
      handler(v) {
        this.mutableValue = v;
      },
    },
  },
  methods: {
    getSelectedOptionValue(v) {
      if (v === null) return null;
      const option = this.options.find((o) => o.value === v);
      return option ? option.value : otherOptionValue;
    },
    parseValue(v) {
      const optionValue = this.getSelectedOptionValue(v);
      if (isOther(optionValue)) this.otherValue = v;
      return v;
    },
    parseInitialValue(v) {
      return this.parseValue(v);
    },
    onOptionChange(v) {
      this.mutableValue = isOther(v) ? this.otherValue : v;
    },
  },
};
</script>
