import { utils } from '@itccompliance/compliance-vue-essentials-plugin';
import dealerRepo from '@/api/repositories/dealer';

const createInitialState = () => ({
  currentDealerId: null,
  isChangingDealer: false,
});

export default {
  namespaced: true,
  state: createInitialState(),
  getters: {
    ...utils.vuex.mapSimpleGetters([
      'currentDealerId',
      'isChangingDealer',
    ]),
    currentDealer: (state, { currentDealerId, dealers }) => dealers
      .find((dealer) => dealer.id === currentDealerId),
    currentDealerHasPreference: (state, { currentDealer }) => (preference) => !!currentDealer?.preferences?.[preference], // eslint-disable-line max-len
    dealers: (state, getters, rootState, { meta }) => meta.dealers || [],
    isMotorcycleDealer: (state, { currentDealer }) => utils.object.deepGet(currentDealer, 'preferences.is_motorcycle_dealer') === true,
    dealerHasWelcomePack: (state, { currentDealer }) => utils.object.deepGet(currentDealer, 'preferences.welcome_pack_approved') === true,
  },
  mutations: {
    ...utils.vuex.mapSimpleMutations([
      'currentDealerId',
      'isChangingDealer',
    ]),
    RESET(state) {
      Object.assign(state, createInitialState());
    },
  },
  actions: {
    reset({ commit }) {
      commit('RESET');
    },
    async setCurrentDealer({ commit }, dealerId) {
      commit('SET_IS_CHANGING_DEALER', true);
      try {
        await dealerRepo.setCurrentDealer(dealerId);
        commit('SET_CURRENT_DEALER_ID', dealerId);
      } finally {
        commit('SET_IS_CHANGING_DEALER', false);
      }
    },
    async verifyCurrentDealer({ commit, dispatch, getters }) {
      if (!getters.currentDealer) {
        if (getters.dealers.length) await dispatch('setCurrentDealer', getters.dealers[0].id);
        else commit('SET_CURRENT_DEALER_ID', null);
      }
    },
    getWelcomePackConfirmationText() {
      return dealerRepo.getWelcomePackConfirmationText();
    },
  },
};
