import Vue from 'vue';
import {
  format as formatDate,
  isDate,
  parseISO,
} from 'date-fns';

const dateFilter = (value, format = 'dd/MM/y | HH:mm') => {
  try {
    let date = value;
    if (!isDate(date)) date = parseISO(date);
    if (date === 'Invalid Date') throw new Error();
    return formatDate(date, format);
  } catch {
    return '-';
  }
};

Vue.filter('date', dateFilter);

export default dateFilter;
