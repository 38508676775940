<template>
  <div
    :id="id"
    :class="`${fieldClass}--radio-button`"
  >
    <b-btn
      v-for="option of options"
      :key="option.value"
      :class="`${fieldClass}--radio-button__button`"
      class="mr-3 px-3"
      :disabled="disabled"
      :variant="getButtonVariant(option)"
      @click="mutableValue = option.value"
    >
      <b-icon
        v-if="option.icon"
        :icon="option.icon"
      />
      {{ option.text }}
    </b-btn>
  </div>
</template>

<script>
import { fieldMixin, utils } from '@itccompliance/compliance-vue-essentials-plugin';

export default {
  name: 'RadioButtonField',
  mixins: [fieldMixin],
  computed: {
    options() {
      const options = utils.parsers.parseObject(utils.object.deepGet(this, 'field.options'));
      let radioOptions = [];
      if (Array.isArray(options)) {
        radioOptions = options.map((option) => {
          if (typeof option === 'object') return option;
          return { value: option, text: option };
        });
      } else {
        radioOptions = Object.keys(options).map((key) => ({ value: key, text: options[key] }));
      }
      return radioOptions;
    },
  },
  methods: {
    getButtonVariant(option) {
      if (option.value === this.mutableValue) {
        if (this.state === false) return 'danger';
        return 'primary';
      }
      return `outline-${this.state === false ? 'danger' : 'dark'}`;
    },
  },
};
</script>
