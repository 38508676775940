import staffListRepo from '@/api/repositories/staffList';

export default {
  namespaced: true,
  actions: {
    createUser(context, data) {
      return staffListRepo.createUser(data);
    },
    getStaffList(context, params) {
      return staffListRepo.getStaffList(params);
    },
    requestPasswordReset(context, data) {
      return staffListRepo.requestPasswordReset(data);
    },
    removeUser(context, data) {
      return staffListRepo.removeUser(data);
    },
    reset() { /*  */ },
  },
};
