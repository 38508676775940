<template>
  <b-toast
    body-class="text-center"
    no-auto-hide
    no-close-button
    title="Consent"
    variant="primary"
    :visible="show"
  >
    <div class="mb-3">
      We use analytics to improve our services.
      By clicking "Allow" you consent to our usage of analytics.
    </div>
    <div class="mb-2">
      <b-btn
        class="mr-2"
        @click="setConsent(true)"
      >
        Allow
      </b-btn>
      <b-btn
        variant="outline-primary"
        @click="setConsent(false)"
      >
        Deny
      </b-btn>
    </div>
    <div>
      <b-btn
        size="sm"
        variant="link"
        @click="showMoreInfo"
      >
        More info
      </b-btn>
    </div>
  </b-toast>
</template>

<script>
import Markdown from '@itccompliance/compliance-vue-essentials-plugin/src/components/markdown.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    show: false,
  }),
  computed: {
    ...mapGetters('profile', { currentUserProfile: 'profile' }),
    ...mapGetters('analytics/consent', ['consentProvidedForUser', 'consentSpecifiedForUser']),
  },
  watch: {
    currentUserProfile: {
      immediate: true,
      deep: true,
      handler(currentUserProfile) {
        if (currentUserProfile && currentUserProfile.id) {
          if (!this.consentSpecifiedForUser(currentUserProfile.id)) {
            this.show = true;
          } else if (this.consentProvidedForUser(currentUserProfile.id)) {
            this.initAnalytics();
          }
        }
      },
    },
  },
  methods: {
    ...mapActions('analytics', { initAnalytics: 'activate' }),
    ...mapActions('analytics/consent', ['setUserConsent']),
    setConsent(consent) {
      this.setUserConsent({ user: this.currentUserProfile.id, consent });
      if (consent) this.initAnalytics();
      this.show = false;
    },
    showMoreInfo() {
      const markdown = `### Hotjar

We use Hotjar in order to better understand our users' needs and to optimize this service and experience. Hotjar uses cookies and other technologies to collect data on our users' behavior and their devices. This includes a device's IP address (processed during your session and stored in a de-identified form), device screen size, device type (unique device identifiers), browser information, geographic location (country only), and the preferred language used to display our website. Hotjar stores this information on our behalf in a pseudonymized user profile. Hotjar is contractually forbidden to sell any of the data collected on our behalf. For further details, please see the ‘about Hotjar' section of [Hotjar's support site](https://help.hotjar.com/hc/en-us/categories/115001323967-About-Hotjar).`;
      this.$compliancePrompt.prompt({
        component: {
          render: (h) => h(Markdown, { props: { markdown } }),
        },
        title: 'Analytics Information',
        dismissable: true,
        size: 'lg',
      });
    },
  },
};
</script>
