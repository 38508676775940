<template>
  <TypeaheadField
    v-bind="{
      ...$attrs,
      ...$props,
      class: `${fieldClass}--select-manufacturer`,
      disabled: disabled || isLoadingManufacturers,
      options: options,
      placeholder: placeholder,
    }"
    v-on="$listeners"
  />
</template>

<script>
import { fieldMixin } from '@itccompliance/compliance-vue-essentials-plugin';
import { mapActions, mapGetters } from 'vuex';
import TypeaheadField from './typeahead.vue';

export default {
  name: 'SelectManufacturerField',
  components: { TypeaheadField },
  mixins: [fieldMixin],
  inheritAttrs: false,
  props: {
    source: {
      type: String,
      default: undefined,
    },
  },
  data: () => ({
    hasInvokedInitialChangeHandler: false,
  }),
  computed: {
    ...mapGetters('vehicle', ['isLoadingManufacturers', 'manufacturers']),
    borderVariant() {
      return this.state ? 'success' : 'danger';
    },
    placeholder() {
      return this.isLoadingManufacturers
        ? 'Loading...'
        : ' - Select a manufacturer - ';
    },
    options() {
      return this.manufacturers.map((m) => m.name);
    },
  },
  watch: {
    isLoadingManufacturers: {
      immediate: false,
      handler(isLoadingManufacturers) {
        if (!isLoadingManufacturers && !this.hasInvokedInitialChangeHandler) {
          // Hack to trigger the change handler on first mount as it is not immediately invoked.
          // We need to trigger the change handler to populate the model field initially.
          this.$parent.onInput(this.field, this.mutableValue, { wasProgrammatic: true });
          this.hasInvokedInitialChangeHandler = true;
        }
      },
    },
  },
  created() {
    this.getManufacturers(this.source);
  },
  methods: {
    ...mapActions('vehicle', ['getManufacturers']),
  },
};
</script>
