import dealRepo from '@/api/repositories/deal';

const createInitialState = () => ({
  pagesLoaded: 0,
  items: [],
});

export default {
  namespaced: true,
  state: createInitialState(),
  getters: {
    documents: (state) => state.items,
  },
  mutations: {
    RESET(state) {
      Object.assign(state, createInitialState());
    },
    SET_ITEMS(state, items) {
      state.items = items || [];
    },
    SET_PAGES_LOADED(state, pages) {
      state.items = pages;
    },
  },
  actions: {
    reset({ commit }) {
      commit('RESET');
    },
    async fetchDocuments({ rootGetters, commit }) {
      const { data } = await dealRepo.getDocuments(rootGetters['deal/deal'].id);
      commit('SET_ITEMS', data.data);
    },
  },
};
