import Repository from '../Repository';

export class RegistrationRepository extends Repository {
  init() {
    return this.client.get('/registration/init');
  }

  getStep(stepKey) {
    return this.client.get(`/registration/step/${stepKey}`);
  }

  saveStep(stepKey, data) {
    return this.client.post(`/registration/step/${stepKey}`, data);
  }
}

export default new RegistrationRepository();
