<template>
  <div>
    <b-list-group class="mb-3">
      <b-list-group-item v-if="!hasError">
        <span
          class="h5 m-0"
        >
          No Error records.
        </span>
      </b-list-group-item>
      <b-list-group-item
        v-for="error in errorMessages"
        v-else
        :key="error.id"
      >
        <h5>
          <BIconExclamationTriangleFill class="mr-1" /> {{ error.title }}
        </h5>
        <p>
          Deal ID: {{ error.dealId || 'n/a' }}
        </p>
        <p style="white-space: pre-line;">
          {{ error.message }}
        </p>
        <span>
          <small>
            {{ error.time | date }}
          </small>
        </span>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BIconExclamationTriangleFill } from 'bootstrap-vue';

export default {
  components: {
    BIconExclamationTriangleFill,
  },
  computed: {
    ...mapGetters('error', ['errorMessages']),
    hasError() {
      return this.errorMessages.length > 0;
    },
  },
};
</script>
