/**
 * Creates a copy of an array with an item replaced
 * @param {Array} array
 * @param {Number} index
 * @param {*} item
 * @returns {Array}
 */
const replaceItem = (array, index, item) => [
  ...array.slice(0, index),
  item,
  ...array.slice(index + 1),
];

export default replaceItem;
