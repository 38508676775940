import { utils } from '@itccompliance/compliance-vue-essentials-plugin';

export default {
  validation: (params, field, formData, fields) => (val) => {
    if (!val) return true;
    const [key] = params;
    const { id } = fields.find((f) => f.key === key);
    const valueLength = utils.parsers.parseNumber(utils.object.deepGet(val, 'length'));
    const test = utils.parsers.parseNumber(formData[id]);
    return valueLength === test;
  },
  message: (params, field, formData, fields) => {
    const [key] = params;
    const { id } = fields.find((f) => f.key === key);
    return `Must contain ${formData[id]} items`;
  },
};
