import { isPlainObject } from 'is-plain-object';

export default (data) => {
  const formData = new FormData();
  Object.entries(data).forEach(([baseKey, baseValue]) => {
    const appendValue = (key, value) => {
      if (Array.isArray(value)) {
        value.forEach((v, index) => {
          appendValue(`${key}[${index}]`, v);
        });
      } else if (isPlainObject(value) && value !== null) {
        Object.entries(value).forEach(([nestedKey, nestedValue]) => {
          appendValue(`${key}[${nestedKey}]`, nestedValue);
        });
      } else formData.append(key, value);
    };
    appendValue(baseKey, baseValue);
  });
  return formData;
};
