import Repository from '../Repository';

export class ComplaintsRepository extends Repository {
  createComplaint(data) {
    return this.client.post('/complaints', data);
  }

  getInsuranceProducts() {
    return this.client.get('/complaints/insurance/products');
  }

  getInsuranceSuppliers() {
    return this.client.get('/complaints/insurance/suppliers');
  }

  getFinanceProducts() {
    return this.client.get('/complaints/finance/products');
  }

  getFinanceProviders() {
    return this.client.get('/complaints/finance/providers');
  }

  getComplaints() {
    return this.client.get('/complaints');
  }

  getComplaintTemplate() {
    return this.client.get('/complaints/templates/summary-resolution');
  }

  uploadComplaintDocument({ complaintId, data }) {
    return this.client.post(`/complaints/${complaintId}/upload-document`, data);
  }
}

export default new ComplaintsRepository();
