export default (profile) => ({
  fields: [
    {
      id: 'email',
      display_type: 'email',
      label: 'Email address',
      rules: ['email', 'required'],
      value: profile.email,
    },
    {
      id: 'last_name',
      display_type: 'text',
      label: 'Surname',
      rules: ['required'],
      value: profile.last_name,
    },
  ],
});
