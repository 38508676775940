import store from '@/store';

/* istanbul ignore next */const Step = () => import(/* webpackChunkName: "registration" */ '@/views/registration/Step.vue');

export default [
  {
    path: '/registration/step/:stepKey',
    name: 'Registration',
    component: Step,
    meta: {
      title: (route) => {
        const step = store.getters['registration/findStepByKey'](route.params.stepKey);
        return [step.label, 'Registration'];
      },
      noLoginRequired: true,
    },
  },
];
