<template>
  <b-spinner v-if="isLoading(loadingKeys.get)" />
  <div v-else-if="formModel">
    <compliance-form-wrapper
      :id="formId"
      class="mb-4"
      :disabled="isClosingPrompt"
      :form-model="formModel"
      :show-submit="false"
      :show-submitting="isSaving"
      @submit="doSaveAgreements"
    />
    <div class="d-flex justify-content-end">
      <b-spinner
        v-if="isSaving"
        class="mr-2"
        small
      />
      <b-btn
        :disabled="isSaving"
        @click="$complianceForm.submit(formId)"
      >
        Submit
      </b-btn>
    </div>
  </div>
</template>

<script>
import { loadingMixin } from '@itccompliance/compliance-vue-essentials-plugin';
import { mapActions } from 'vuex';

export default {
  mixins: [
    loadingMixin,
  ],
  props: {
    promptId: {
      type: [String, Number],
      default: null,
    },
  },
  data: () => ({
    formId: 'agreements-form',
    formModel: null,
    isClosingPrompt: false,
    loadingKeys: {
      get: 'get-agreements',
      save: 'save-agreements',
    },
  }),
  computed: {
    isSaving() {
      return this.isLoading(this.loadingKeys.save);
    },
  },
  created() {
    this.doGetAgreements();
  },
  methods: {
    ...mapActions('agreements', [
      'getAgreements',
      'saveAgreements',
    ]),
    async doGetAgreements() {
      this.setLoading(this.loadingKeys.get);
      try {
        const response = await this.getAgreements();
        this.formModel = { fields: response.data.data };
      } finally {
        this.unsetLoading(this.loadingKeys.get);
      }
    },
    async doSaveAgreements(formData) {
      this.setLoading(this.loadingKeys.save);
      try {
        await this.saveAgreements(formData);
        this.resolvePrompt(true);
      } finally {
        this.unsetLoading(this.loadingKeys.save);
      }
    },
    resolvePrompt(result) {
      if (this.promptId) {
        this.isClosingPrompt = true;
        this.$compliancePrompt.resolve(this.promptId, result);
      }
      if (result) this.$router.go(); // refresh page
    },
  },
};
</script>
