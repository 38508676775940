export default {
  validation: (...args) => {
    const isValueless = (v) => v === null || typeof v === 'undefined';
    if (args.length === 1) {
      const val = args[0];
      return !(val === '' || isValueless(val));
    }
    return (val) => {
      const params = args[0];
      const valueToNotEqual = params[0];
      if (isValueless(val) && (isValueless(valueToNotEqual) || valueToNotEqual === '')) return false;
      try {
        return val.toString() !== valueToNotEqual;
      } catch {
        return true;
      }
    };
  },
  message: (params) => `Must not be '${params[0]}'`,
};
