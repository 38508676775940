import complaintsRepo from '@/api/repositories/complaints';

export default {
  namespaced: true,
  actions: {
    createComplaint(context, data) {
      return complaintsRepo.createComplaint(data);
    },
    getInsuranceProducts() {
      return complaintsRepo.getInsuranceProducts();
    },
    getInsuranceSuppliers() {
      return complaintsRepo.getInsuranceSuppliers();
    },
    getFinanceProducts() {
      return complaintsRepo.getFinanceProducts();
    },
    getFinanceProviders() {
      return complaintsRepo.getFinanceProviders();
    },
    getComplaints() {
      return complaintsRepo.getComplaints();
    },
    getComplaintTemplate() {
      return complaintsRepo.getComplaintTemplate();
    },
    uploadComplaintDocument(context, { complaintId, data }) {
      return complaintsRepo.uploadComplaintDocument({ complaintId, data });
    },
    reset() {
    },
  },
};
