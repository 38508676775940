import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'; // dependency of fandi-ui
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import '@itccompliance/compliance-fandi-ui/src/components/global/index';
import './plugins/bootstrap-vue';
import './plugins/essentials';
import './plugins/faIcons';
import './plugins/mdIcons';
import './filters/capitalise';
import './filters/date';
import toastMixin from '@/mixins/toast';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.mixin(toastMixin);
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
