import store from '../store';

const checkRequirements = (getterBasePath, checkerKeys, requirements, ref) => {
  for (let i = 0; i < checkerKeys.length; i += 1) {
    const checkerKey = checkerKeys[i];
    const requirement = requirements[checkerKey];
    if (requirement && !ref.getters[`${getterBasePath}/${checkerKey}`](requirement)) return false;
  }
  return true;
};

const checkPermissions = (item, ref = store) => {
  if (!item) return true;

  const preferencesSatisfied = checkRequirements(
    'dealer',
    ['currentDealerHasPreference'],
    item.meta?.preferenceRequirements || {},
    ref,
  );
  if (!preferencesSatisfied) return false;

  const permissionsSatisfied = checkRequirements(
    'auth',
    [
      'hasPermission',
      'hasEveryPermission',
      'hasSomePermissions',
    ],
    item.meta?.permissionRequirements || {},
    ref,
  );
  if (!permissionsSatisfied) return false;

  return true;
};

export { checkPermissions };

export default {
  checkPermissions,
};
