import { utils } from '@itccompliance/compliance-vue-essentials-plugin';

const createInitialState = () => ({
  permissions: [],
});

// eslint-disable-next-line max-len
const createHasPermissionsGetter = (method) => (state, { hasPermission }) => (permissions) => permissions[method](hasPermission);

export default {
  namespaced: true,
  state: createInitialState(),
  getters: {
    ...utils.vuex.mapSimpleGetters([
      'permissions',
    ]),
    hasPermission: (state, { permissions }) => (permission) => permissions.includes(permission),
    hasSomePermissions: createHasPermissionsGetter('some'),
    hasEveryPermission: createHasPermissionsGetter('every'),
  },
  mutations: {
    ...utils.vuex.mapSimpleMutations([
      'permissions',
    ]),
    RESET(state) {
      Object.assign(state, createInitialState());
    },
  },
  actions: {
    logout({ dispatch }) {
      dispatch('reset', null, { root: true });
      window.location.href = '/app/auth/logout';
    },
    reset({ commit }) {
      commit('RESET');
    },
  },
};
