import Repository from '../Repository';

export class DocumentsRepository extends Repository {
  searchDocuments(params) {
    return this.client.get('guides', { params });
  }

  getPublicDocuments() {
    return this.client.get('guides/public');
  }

  downloadDocument(documentId) {
    return this.client.get(`guides/${documentId}/download`);
  }
}

export default new DocumentsRepository();
