import hotjar from '@/lib/hotjar';
import consent from './consent';

const services = [
  {
    id: process.env.VUE_APP_HOTJAR_SITE_ID,
    key: 'hotjar',
    initHandler: hotjar,
  },
];

const initialState = () => ({
  active: false,
});

export default {
  namespaced: true,
  modules: {
    consent,
  },
  state: initialState(),
  getters: {
    availableServices: () => services.filter((service) => !!service.id),
    isAvailable: (state, { availableServices }) => availableServices.length > 0,
  },
  mutations: {
    RESET(state) {
      Object.assign(state, initialState());
    },
    SET_ACTIVE(state, active) {
      state.active = active;
    },
  },
  actions: {
    activate({ commit, getters }) {
      if (!getters.active) {
        getters.availableServices.forEach((service) => {
          try {
            service.initHandler();
            if (!getters.active) commit('SET_ACTIVE', true);
          } catch { /**/ }
        });
      }
    },
    reset({ commit }) {
      commit('RESET');
    },
  },
};
