import dealRepo from '@/api/repositories/deal';

const createInitialState = () => ({
  items: [],
});

export default {
  namespaced: true,
  state: createInitialState(),
  getters: {
    activity: (state) => state.items,
  },
  mutations: {
    RESET(state) {
      Object.assign(state, createInitialState());
    },
    SET_ITEMS(state, items) {
      state.items = items || [];
    },
  },
  actions: {
    reset({ commit }) {
      commit('RESET');
    },
    async fetchActivity({ rootGetters, commit }) {
      const response = await dealRepo.getActivity(rootGetters['deal/deal'].id);
      commit('SET_ITEMS', response.data.data);
    },
  },
};
