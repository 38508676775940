import Repository from '../Repository';

export class StaffListRepository extends Repository {
  getStaffList(params) {
    return this.client.get('/staff-list', { params });
  }

  createUser(data) {
    return this.client.post('/staff-list', data);
  }

  requestPasswordReset(data) {
    return this.client.post('/staff-list/password-reset', data);
  }

  removeUser(params) {
    return this.client.delete('/staff-list', { params });
  }
}

export default new StaffListRepository();
