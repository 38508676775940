import fandfRepo from '@/api/repositories/fandf';

export default {
  namespaced: true,
  actions: {
    sendInvite(context, email) {
      return fandfRepo.sendInvite(email);
    },
    reset() { /*  */ },
  },
};
