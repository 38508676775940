import Vue from 'vue';
import VueRouter from 'vue-router';
import { checkPermissions } from '../utils/permissions';
import routes from './routes';
import store from '../store';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => savedPosition || { y: 0 },
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.noLoginRequired || to.meta.noDealerRequired) next();
  else {
    const requiresTraining = typeof to.meta.requiresTraining === 'function'
      ? to.meta.requiresTraining(to)
      : to.meta.requiresTraining;
    if (!checkPermissions(to) || (requiresTraining && !store.getters['profile/userHasTraining'])) {
      const redirectRouteName = 'dashboard';
      next({ name: redirectRouteName, params: { forceLeaveDeal: true } });
    } else next();
  }
});

export default router;
