<template>
  <BIconChevronDown
    :style="{
      'transition-property': 'transform',
      'transition-duration': '300ms',
      transform,
    }"
    v-bind="$attrs"
  />
</template>

<script>
import { BIconChevronDown } from 'bootstrap-vue';

export default {
  components: {
    BIconChevronDown,
  },
  props: {
    collapsed: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    transform() {
      return `rotateX(${this.collapsed ? '0' : '180'}deg)`;
    },
  },
};
</script>
