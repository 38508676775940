import agreementsRepo from '@/api/repositories/agreements';

export default {
  namespaced: true,
  actions: {
    getAgreements() {
      return agreementsRepo.getAgreements();
    },
    saveAgreements(context, answers) {
      return agreementsRepo.saveAgreements(answers);
    },
    reset() { /*  */ },
  },
};
