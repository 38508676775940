import Vue from 'vue';

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// https://bootstrap-vue.org/docs/reference/settings
Vue.use(BootstrapVue, {
  BButton: {
    variant: 'primary',
  },
  BToast: {
    toaster: 'b-toaster-bottom-right',
  },
});

Vue.use(BootstrapVueIcons);
