import { utils } from '@itccompliance/compliance-vue-essentials-plugin';

const createInitialState = () => ({
  badgeVisible: false,
  recaptchaMounted: false,
  siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY || null,
});

export default {
  namespaced: true,
  state: createInitialState(),
  actions: {
    async executeRecaptcha({ state }, args) {
      try {
        const token = await window.grecaptcha.execute(state.siteKey, args);
        return token;
      } catch (e) {
        return null;
      }
    },
    mountRecaptcha({ commit, state }) {
      if (state.siteKey && !state.recaptchaMounted) {
        const el = document.createElement('script');
        el.setAttribute('src', `https://www.google.com/recaptcha/api.js?render=${state.siteKey}`);
        el.onload = () => {
          window.grecaptcha.ready(() => {
            commit('SET_RECAPTCHA_MOUNTED', true);
          });
        };
        document.querySelector('head').appendChild(el);
      }
    },
    setBadgeVisibility({ commit }, visible) {
      const badges = document.querySelectorAll('.grecaptcha-badge');
      badges.forEach((el) => {
        el.style.setProperty('visibility', visible ? 'visible' : 'hidden');
      });
      commit('SET_BADGE_VISIBLE', visible);
    },
    reset() {},
  },
  getters: {
    ...utils.vuex.mapSimpleGetters(['recaptchaMounted']),
  },
  mutations: {
    ...utils.vuex.mapSimpleMutations([
      'recaptchaMounted',
      'badgeVisible',
    ]),
  },
};
